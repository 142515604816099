import React, { useCallback, useEffect, useRef } from 'react';
import { Box, Card, CircularProgress } from '@chakra-ui/react';

type LoaderProps = {
  isOpen?: boolean;
};

export default function Loader({ isOpen = false }: LoaderProps) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  const openDialog = useCallback(() => {
    if (!dialogRef?.current?.open) {
      dialogRef?.current?.showModal();
    }
  }, []);

  const closeDialog = useCallback(() => {
    dialogRef?.current?.close();
  }, []);

  useEffect(() => {
    if (isOpen) {
      openDialog();
    } else {
      closeDialog();
    }
  }, [isOpen]);

  return (
    <Box
      bg="none"
      outline="none"
      as="dialog"
      ref={dialogRef}
      aria-label="Por favor espere..."
      sx={{
        '&::backdrop': {
          backdropFilter: 'blur(5px)',
        },
      }}
    >
      <Card bg="gray.500" px={20} py={16}>
        <CircularProgress isIndeterminate color="gray.700" size={16} />
      </Card>
    </Box>
  );
}
