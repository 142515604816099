import { extendTheme } from '@chakra-ui/react';
import Button from 'theme/button';
import Heading from 'theme/heading';
import Text from 'theme/text';
import { radioTheme } from 'theme/radio';
import { inputTheme } from 'theme/input';
import { cardTheme } from 'theme/card';
import Progress from 'theme/progress';
import { tooltipTheme } from 'theme/tooltip';
import { checkboxTheme } from 'theme/checkbox';
import FormLabel from 'theme/label';
import { alertTheme } from 'theme/alert';

export const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'gray.200',
        _dark: {
          bg: 'initial',
        },
      },
      '::-webkit-scrollbar': {
        height: '0.3125rem',
        width: '0.3125rem',
      },
      '::-webkit-scrollbar-track': {
        bgColor: 'gray.300',
        borderRadius: '4px',
      },
      '::-webkit-scrollbar-thumb': {
        bgColor: 'brand.500',
        borderRadius: '4px',
      },
    },
  },
  colors: {
    brand: {
      50: '#FFF0F2',
      100: '#FED3D7',
      200: '#FCB0B9',
      300: '#FB8E9A',
      400: '#FB8E9A',
      500: '#FF285B',
      600: '#F7172F',
      700: '#D4071D',
      800: '#A30516',
      900: '#720410',
    },
    gray: {
      50: '#FFFFFF',
      100: '#FFFFFF',
      200: '#F1F2F4',
      300: '#D5D8DC',
      400: '#B9BEC5',
      500: '#9DA4AE',
      600: '#67707E',
      700: '#505862',
      800: '#22262A',
      900: '#000000',
    },
  },
  components: {
    Alert: alertTheme,
    Button,
    Card: cardTheme,
    Checkbox: checkboxTheme,
    FormLabel,
    Heading,
    Input: inputTheme,
    Progress,
    Radio: radioTheme,
    Text,
    Tooltip: tooltipTheme,
  },
  fonts: {
    body: 'Montserrat, sans-serif',
    heading: 'Montserrat, sans-serif',
    mono: 'Menlo, monospace',
  },
});
