import { UserProfile } from 'types/user';
import { axiosInstance } from 'services/axios-client';

const { REACT_APP_API_URL } = process.env;

export const getUserProfile = async (uid: string) => {
  try {
    return await axiosInstance.get(`${REACT_APP_API_URL}/user-profile/${uid}`);
  } catch (e) {
    console.log(e);
  }
};

type PostUserProfileParams = {
  payload: UserProfile;
};

export const postUserProfile = async ({ payload }: PostUserProfileParams) => {
  try {
    return await axiosInstance.post(
      `${REACT_APP_API_URL}/user-profile`,
      payload
    );
  } catch (e) {
    console.log(e);
  }
};

type PutUserProfileParams = {
  uid: string;
  payload: Partial<UserProfile>;
};

export const putUserProfile = async ({
  uid,
  payload,
}: PutUserProfileParams) => {
  try {
    return await axiosInstance.put(
      `${REACT_APP_API_URL}/user-profile/${uid}`,
      payload
    );
  } catch (e) {
    console.log(e);
  }
};
