import { UserAction, UserState } from 'types/user';

const initialUser = {
  uid: '',
  displayName: '',
  token: '',
  loading: false,
};

export const initialState: UserState = {
  user: initialUser,
  loading: true,
};

export const reducer = (
  state: UserState = initialState,
  action: Partial<UserAction>
): UserState => {
  switch (action.type) {
    case 'LOADING_ON':
      return {
        ...state,
        loading: true,
      };
    case 'LOADING_OFF':
      return {
        ...state,
        loading: false,
      };
    case 'LOGIN_SUCCESS':
    case 'GOOGLE_LOGIN_SUCCESS':
    case 'REGISTER_SUCCESS':
      return {
        ...state,
        user: action.payload || initialUser,
      };
    case 'LOGIN_FAILED':
    case 'REGISTER_FAILED':
      return {
        ...state,
        ...initialState,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: initialUser,
      };
    default:
      return state;
  }
};
