import { Box, Card, Flex, HStack, Text } from '@chakra-ui/react';
import { BilledCalendarIcon } from 'components/Icons';

import moment from 'moment';
import IconDecider from './IconDecider';
import { colorDecider } from 'utils/string';
import { Meeting } from 'types/meeting';

const MeetingCard = (props: Meeting) => {
  const { acquaintanceName, company, eventDateTime, status, uid, onOpen } =
    props;
  const date = moment(eventDateTime)
    .format('MMMM Do YYYY, h:mm a')
    .split(',')[0];
  const hour = moment(eventDateTime)
    .format('MMMM Do YYYY, h:mm a')
    .split(',')[1];

  const gmt = moment(eventDateTime).format('Z');
  const lineThrough = status === 'FAILED' || status === 'CANCELLED';

  return (
    <Flex
      className="meeting-first-card"
      justifyContent="space-between"
      alignItems="center"
      py={2}
      px={5}
    >
      <Flex alignItems="center">
        <Box borderRadius="5px">
          <Card>
            <BilledCalendarIcon
              borderRadius="15px"
              backgroundColor={
                status === 'BILLED' || status === 'COMPLETED'
                  ? 'rgba(52, 168, 83, 0.27)'
                  : status === 'PENDING'
                  ? 'rgba(255, 40, 91, 0.22)'
                  : 'gray.300'
              }
              color={
                status === 'BILLED' || status === 'COMPLETED'
                  ? '#219653'
                  : status === 'PROCESSING' ||
                    status === 'CANCELLED' ||
                    status === 'FAILED'
                  ? '#67707E'
                  : '#FF285B'
              }
              fontSize="3rem"
            />
          </Card>
        </Box>
        <Box ml="2.5">
          <Text variant="caption1">
            {company} - {acquaintanceName}
          </Text>
          <HStack>
            <Text
              textDecoration={lineThrough ? 'line-through' : ''}
              pt={1}
              variant="caption2"
            >
              {status === 'PROCESSING'
                ? 'Pending'
                : hour + '-' + date + ` (GMT ${gmt})`}
            </Text>
            {status === 'FAILED' && (
              <Text pt={1} variant="caption2">
                - Unsuccessfull Meeting
              </Text>
            )}
            {status === 'CANCELLED' && (
              <Text pt={1} variant="caption2">
                - Cancelled by you
              </Text>
            )}
          </HStack>
        </Box>
      </Flex>
      <Box>
        <IconDecider
          uid={uid}
          onOpen={onOpen}
          colorDicider={colorDecider}
          status={status}
        />
      </Box>
    </Flex>
  );
};

export default MeetingCard;
