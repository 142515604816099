import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'components/Header/Header';
import { UserContext } from 'context/provider/UserProvider';
import LoaderSpinner from 'components/LoaderSpinner/LoaderSpinner';
import { Box } from '@chakra-ui/react';

const MainLayout = () => {
  const {
    state: { loading },
  } = useContext(UserContext);
  return (
    <>
      <Header />
      {loading && <LoaderSpinner />}
      {!loading && (
        <Box as="main" px="8%" mx="auto">
          <Outlet />
        </Box>
      )}
    </>
  );
};

export default MainLayout;
