import { PaginationData } from 'components/Pagination/Pagination';
import { UserContext } from 'context/provider/UserProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { cancelMeeting, getUserMeetingsInfo } from 'services/meeting';
import { Meeting } from 'types/meeting';

const useMeetings = () => {
  const { state } = useContext(UserContext);
  const {
    user: { uid },
  } = state;
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<PaginationData>({
    currentPage: 0,
    currentElements: 0,
    totalPages: 0,
    totalElements: 0,
  });

  const getMeetings = useCallback(async () => {
    try {
      const result = await getUserMeetingsInfo({ uid, page });
      setMeetings(result?.data?.meetings);
      setPagination(result?.data.pagination);
    } catch (error) {
      console.log(error);
    }
  }, [page]);

  useEffect(() => {
    getMeetings();
    return () => {};
  }, [page]);

  const handleMeetingPageChange = (num: number) => setPage(num);

  const handleMeetingCancel = async (uid: string | null, reason: string) => {
    const result = await cancelMeeting({ uid, reason });
    if ((result as Meeting)?.uid) {
      const canceledMeet = meetings.filter(
        (meet: Meeting) => meet.uid !== Number(uid)
      );
      setMeetings([...canceledMeet, result as Meeting]);
    } else {
      console.log('algo salio mal');
    }
  };

  return {
    meetings,
    pagination,
    page,
    handleMeetingPageChange,
    handleMeetingCancel,
  };
};

export default useMeetings;
