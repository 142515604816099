import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { DotIcon } from 'components/Icons';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { MetricsDataset } from 'components/Metrics/MetricsDisplay';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend);

type MetricCardRightSideProps = {
  dataset: Partial<MetricsDataset> | undefined;
};

type TextComponentProps = {
  color: string;
  text: string;
};

const MetricCardRightSide = ({ dataset }: MetricCardRightSideProps) => {
  const [data, setData] = useState<(number | undefined)[]>([]);

  const { t } = useTranslation();
  const metricHelpers = [
    { color: '#a3052a', text: t('metrics.statistics.pending') },
    { color: '#F9495C', text: t('metrics.statistics.successful') },
    { color: '#FFA8B2', text: t('metrics.statistics.paid') },
    { color: '#fed3dd', text: t('metrics.statistics.cancelled') },
  ];

  const pieData = {
    labels: [],
    datasets: [
      {
        data: !data.length ? [100] : data,
        backgroundColor: !data.length
          ? ['#d9d9d9']
          : ['#a3052a', '#ff285b', '#fa6b8c', '#fed3dd'],
        hoverBackgroundColor: !data.length
          ? ['#d9d9d9']
          : ['#a3052a', '#ff285b', '#fa6b8c', '#fed3dd'],
        borderWidth: 0,
      },
    ],
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: data.length > 0,
      },
    },
  };

  useEffect(() => {
    const checkUndefinedDatasetProperties = () =>
      !dataset || Object.values(dataset).every((value) => value === undefined);

    if (dataset && !checkUndefinedDatasetProperties()) {
      const {
        pendingCount,
        completedCount,
        billedCount,
        cancelledCount,
      }: Partial<MetricsDataset> = dataset;

      setData([pendingCount, completedCount, billedCount, cancelledCount]);
    } else {
      setData([]);
    }
  }, [dataset]);

  return (
    <Flex align="center" gap={5} wrap="wrap">
      <Box w="10rem" h="10rem">
        <Pie data={pieData} options={pieOptions} />
      </Box>
      <Flex gap={2} direction="column">
        {metricHelpers.map((item, index) => (
          <TextComponent key={index} {...item} />
        ))}
      </Flex>
    </Flex>
  );
};

export default MetricCardRightSide;

const TextComponent = (props: TextComponentProps) => {
  const { text, color } = props;
  return (
    <HStack>
      <DotIcon color={color} />
      <Text variant="caption2">{text}</Text>
    </HStack>
  );
};
