import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    fontSize: 'md',
    mt: '0.05em',
  },
  control: {
    borderColor: 'gray.600',
    marginRight: 0.5,

    _checked: {
      bg: 'brand.500',
      border: 0,
    },
  },
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });
