import { defineStyleConfig } from '@chakra-ui/react';

const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: '700',
    borderRadius: 8,
  },
  sizes: {
    sm: {
      fontSize: '0.875rem',
      px: 2.5,
      h: 8,
    },
    md: {
      fontSize: '1rem',
      px: 4,
      h: 9,
    },
    lg: {
      fontSize: '1.125rem',
      px: 6,
      h: 12,
    },
    xl: {
      fontSize: '1.125rem',
      px: 3,
      h: 14,
    },
  },
  variants: {
    ghost: {
      color: 'gray.800',
      _dark: {
        color: 'gray.300',
        _hover: {
          color: 'gray.800',
        },
        _disabled: {
          _hover: {
            color: 'gray.300',
          },
        },
      },
      _hover: {
        bg: 'gray.200',
      },
      _disabled: {
        color: 'gray.600',
        _hover: {
          color: 'gray.600',
        },
      },
    },
    outline: {
      border: '2px solid',
      borderColor: 'brand.500',
      color: 'brand.500',
    },
    solid: {
      bg: 'brand.500',
      color: 'white',
      _disabled: {
        bg: 'brand.300',
      },
      _hover: {
        bg: 'brand.600',
        _disabled: {
          bg: 'brand.300',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
});

export default Button;
