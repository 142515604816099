import { HStack, Heading, Stack, Text, Box, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type MetricCardHeaderProps = {
  level: number;
};

const MetricCardHeader = ({ level }: MetricCardHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Stack>
      <HStack>
        <Heading
          display="inilne-flex"
          alignItems="center"
          lineHeight="1em"
          variant="title6"
          as="h4"
        >
          <Box as="span" verticalAlign="middle">
            {`${t('metrics.my_level')} `}
          </Box>
          <Box
            as="span"
            color="brand.500"
            fontSize="2rem"
            verticalAlign="middle"
          >
            {level}
          </Box>
        </Heading>
      </HStack>
      <Tooltip
        hasArrow
        arrowPadding={80}
        placement="top-end"
        bg="gray.100"
        label={`${t('metrics.level_up_tooltip')}`}
        p={2}
        color="gray.800"
        maxW="163px"
        textAlign="center"
        borderRadius="4px"
        boxShadow="0 0 8px 1px rgba(0, 0, 0, 0.17)"
      >
        <Text color="gray.600" colorScheme="gray" variant="subtitle3" m={0}>
          {t('metrics.how_to_level_up')}
        </Text>
      </Tooltip>
    </Stack>
  );
};

export default MetricCardHeader;
