import { defineStyleConfig } from '@chakra-ui/react';

const Progress = defineStyleConfig({
  baseStyle: {
    track: {
      bg: 'gray.300',
      borderRadius: '8px',
    },
    filledTrack: {
      bg: 'brand.500',
      borderRadius: '8px',
    },
  },
});

export default Progress;
