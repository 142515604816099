import { createIcon } from '@chakra-ui/icons';

export const CalendarIcon = createIcon({
  displayName: 'Calendar',
  viewBox: '0 0 18 20',
  path: (
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M4 9H6V11H4V9ZM18 4V18C18 19.1 17.1 20 16 20H2C0.89 20 0 19.1 0 18L0.00999999 4C0.00999999 2.9 0.89 2 2 2H3V0H5V2H13V0H15V2H16C17.1 2 18 2.9 18 4ZM2 6H16V4H2V6ZM16 18V8H2V18H16ZM12 11H14V9H12V11ZM8 11H10V9H8V11Z"
      fill="currentColor"
    />
  ),
});
export const BilledCalendarIcon = createIcon({
  displayName: 'Billed Calendar',
  viewBox: '0 0 48 48',
  path: (
    <path
      d="M30.6665 16.5H29.8332V14.8333H28.1665V16.5H19.8332V14.8333H18.1665V16.5H17.3332C16.4165 16.5 15.6665 17.25 15.6665 18.1667V31.5C15.6665 32.4167 16.4165 33.1667 17.3332 33.1667H30.6665C31.5832 33.1667 32.3332 32.4167 32.3332 31.5V18.1667C32.3332 17.25 31.5832 16.5 30.6665 16.5ZM30.6665 31.5H17.3332V22.3333H30.6665V31.5ZM30.6665 20.6667H17.3332V18.1667H30.6665V20.6667Z"
      fill="currentColor"
    />
  ),
});
export const CalendarIconClose = createIcon({
  displayName: 'Calendar Icon Close',
  viewBox: '0 0 18 20',
  path: (
    <path
      d="M16 2H15V0H13V2H5V0H3V2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V8H16V18ZM2 6V4H16V6H2ZM5.23 15.41L6.29 16.47L8.73 14.03L11.17 16.47L12.23 15.41L9.79 12.97L12.23 10.53L11.17 9.47L8.73 11.91L6.29 9.47L5.23 10.53L7.67 12.97L5.23 15.41Z"
      fill="currentColor"
    />
  ),
});

export const HourIcon = createIcon({
  displayName: 'Hour Icon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z"
      fill="currentColor"
    />
  ),
});

export const CalendarEmptyIcon = createIcon({
  displayName: 'CalendarEmpty',
  viewBox: '0 0 20 22',
  path: (
    <path
      d="M18 2H17V0H15V2H5V0H3V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM18 20H2V9H18V20ZM18 7H2V4H18V7Z"
      fill="currentColor"
    />
  ),
});

export const CalendarCheckIcon = createIcon({
  displayName: 'CalendarCheckIcon',
  viewBox: '0 0 20 22',
  path: (
    <path
      d="M16 2H15V0H13V2H5V0H3V2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V8H16V18ZM2 6V4H16V6H2ZM7.56 16.46L13.49 10.53L12.43 9.47L7.56 14.34L5.45 12.23L4.39 13.29L7.56 16.46Z"
      fill="#219653"
    />
  ),
});

export const CheckIcon = createIcon({
  displayName: 'Check',
  viewBox: '0 0 18 14',
  path: (
    <path
      d="M5.9999 11.2L1.7999 7.00001L0.399902 8.40001L5.9999 14L17.9999 2.00001L16.5999 0.600006L5.9999 11.2Z"
      fill="currentColor"
    />
  ),
});

export const ChevronDownIcon = createIcon({
  displayName: 'ChevronDown',
  viewBox: '0 0 12 8',
  path: (
    <path
      d="M1.41 0.589996L6 5.17L10.59 0.589996L12 2L6 8L0 2L1.41 0.589996Z"
      fill="currentColor"
    />
  ),
});

export const ChevronLeftIcon = createIcon({
  displayName: 'ChevronLeft',
  viewBox: '0 0 8 12',
  path: (
    <path
      d="M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z"
      fill="currentColor"
    />
  ),
});

export const ChevronLeftLightIcon = createIcon({
  displayName: 'ChevronLeftLight',
  viewBox: '0 0 13 20',
  path: (
    <path
      d="M12.5101 1.87001L10.7301 0.100006L0.840088 10L10.7401 19.9L12.5101 18.13L4.38009 10L12.5101 1.87001Z"
      fill="currentColor"
    />
  ),
});

export const ChevronRightIcon = createIcon({
  displayName: 'ChevronRight',
  viewBox: '0 0 8 12',
  path: (
    <path
      d="M0.590088 10.59L5.17009 6L0.590088 1.41L2.00009 0L8.00009 6L2.00009 12L0.590088 10.59Z"
      fill="currentColor"
    />
  ),
});

export const ChevronRightLightIcon = createIcon({
  displayName: 'ChevronRightLight',
  viewBox: '0 0 13 20',
  path: (
    <path
      d="M0.48999 18.13L2.25999 19.9L12.16 10L2.25999 0.100006L0.48999 1.87001L8.61999 10L0.48999 18.13Z"
      fill="currentColor"
    />
  ),
});

export const ChevronUpIcon = createIcon({
  displayName: 'ChevronUp',
  viewBox: '0 0 12 8',
  path: (
    <path
      d="M1.41 7.41L6 2.83L10.59 7.41L12 6L6 0L0 6L1.41 7.41Z"
      fill="currentColor"
    />
  ),
});

export const CurrencyIcon = createIcon({
  displayName: 'Currency',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10.31 9.14C8.54 8.69 7.97 8.2 7.97 7.47C7.97 6.63 8.76 6.04 10.07 6.04C11.45 6.04 11.97 6.7 12.01 7.68H13.72C13.67 6.34 12.85 5.11 11.23 4.71V3H8.9V4.69C7.39 5.01 6.18 5.99 6.18 7.5C6.18 9.29 7.67 10.19 9.84 10.71C11.79 11.17 12.18 11.86 12.18 12.58C12.18 13.11 11.79 13.97 10.08 13.97C8.48 13.97 7.85 13.25 7.76 12.33H6.04C6.14 14.03 7.4 14.99 8.9 15.3V17H11.24V15.33C12.76 15.04 13.96 14.17 13.97 12.56C13.96 10.36 12.07 9.6 10.31 9.14Z"
      fill="currentColor"
    />
  ),
});

export const EditIcon = createIcon({
  displayName: 'Edit',
  viewBox: '0 0 19 18',
  path: (
    <path
      d="M11.06 6.02L11.98 6.94L2.92 16H2V15.08L11.06 6.02ZM14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0ZM11.06 3.19L0 14.25V18H3.75L14.81 6.94L11.06 3.19Z"
      fill="currentColor"
    />
  ),
});

export const HomeIcon = createIcon({
  displayName: 'Home',
  viewBox: '0 0 20 17',
  path: (
    <path
      d="M10 2.69L15 7.19V15H13V9H7V15H5V7.19L10 2.69ZM10 0L0 9H3V17H9V11H11V17H17V9H20L10 0Z"
      fill="currentColor"
    />
  ),
});

export const PlusIcon = createIcon({
  displayName: 'Plus',
  viewBox: '0 0 14 14',
  path: <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="currentColor" />,
});

export const TimesIcon = createIcon({
  displayName: 'Times',
  viewBox: '0 0 14 14',
  path: (
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="currentColor"
    />
  ),
});

export const TrashIcon = createIcon({
  displayName: 'Trash',
  viewBox: '0 0 14 18',
  path: (
    <path
      d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z"
      fill="currentColor"
    />
  ),
});

export const DotIcon = createIcon({
  displayName: 'Dot Icon',
  viewBox: '0 0 13 13',
  path: (
    <path
      fill="currentColor"
      d="M6.80071 12.8177C10.1705 12.8177 12.9022 10.086 12.9022 6.71624C12.9022 3.34648 10.1705 0.614746 6.80071 0.614746C3.43095 0.614746 0.699219 3.34648 0.699219 6.71624C0.699219 10.086 3.43095 12.8177 6.80071 12.8177Z"
    />
  ),
});
