import CalendlyWidget, {
  CalendlyWidgetProps,
} from 'components/Calendly/CalendlyWidget/CalendlyWidget';
import React, { useContext } from 'react';
import { UserContext } from 'context/provider/UserProvider';
import { UserStatePayload } from 'types/user';

export default function NewMeetingCalendly({ onSuccess }: CalendlyWidgetProps) {
  const {
    state: { user },
  } = useContext(UserContext);

  const { displayName, email }: Partial<UserStatePayload> = user;

  return (
    <CalendlyWidget
      onSuccess={onSuccess}
      prefill={{ name: displayName as string, email: email as string }}
    />
  );
}
