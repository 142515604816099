import { ExamPayload } from 'types/exam';
import { axiosInstance } from 'services/axios-client';

const { REACT_APP_API_URL } = process.env;

type GetExamParams = { uid: number };

export const getExam = async ({ uid }: GetExamParams) => {
  try {
    return await axiosInstance.get(`${REACT_APP_API_URL}/exam/${uid}`);
  } catch (e) {
    console.log(e);
  }
};

type PostExamParams = {
  uid: string;
  examUid: number;
  payload: ExamPayload;
};

export const putExam = async ({ uid, examUid, payload }: PostExamParams) => {
  try {
    return await axiosInstance.put(
      `${REACT_APP_API_URL}/user-profile/${uid}/exam/${examUid}`,
      payload
    );
  } catch (e) {
    console.log(e);
  }
};
