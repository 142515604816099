import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'context/provider/UserProvider';
import { useLocation } from 'react-router-dom';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as Person } from 'assets/img/landing-person.svg';
import { useTranslation } from 'react-i18next';
import GoogleLogo from 'assets/img/google.svg';

const Landing = () => {
  const { googleAuth } = useContext(UserContext);
  const [banned, setBanned] = useState<boolean>(false);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isBanned = queryParams.get('banned');
    if (isBanned) {
      setBanned(true);
    }
    return () => {};
  }, [location]);

  return (
    <>
      {banned && (
        <Alert status="error" maxW="968px" m="auto">
          <AlertIcon />
          <AlertTitle>{t('landing.banned.title')}</AlertTitle>
          <AlertDescription>{t('landing.banned.description')}</AlertDescription>
        </Alert>
      )}
      <Grid
        alignItems="center"
        as="section"
        autoFlow="column"
        justifyItems="center"
        minH="calc(100vh - 107px)"
        w="100%"
      >
        <Grid
          m={0}
          templateColumns={{ md: '1fr 1fr', lg: '1.5fr 1fr' }}
          templateAreas={{
            base: `
              "person"
              "sign-in"  
            `,
            md: `
              "sign-in person"
            `,
          }}
          gap={6}
          maxW="1368px"
          alignItems="center"
          justifyItems="center"
          w="100%"
        >
          <GridItem
            area="sign-in"
            display="flex"
            flexDir="column"
            justifyContent="center"
            gap={4}
            maxW="40rem"
          >
            <Heading as="h3" variant="title4">
              {t('landing.title')}
            </Heading>
            <Text variant="caption1" colorScheme="gray.700">
              {t('landing.welcome')}
            </Text>
            <Flex mt={3.5}>
              <Button
                onClick={googleAuth}
                display="flex"
                gap={3}
                bgColor="gray.100"
                borderRadius={4}
                boxShadow="0px 0px 2.33043px rgba(0, 0, 0, 0.084), 0px 1.55362px 2.33043px rgba(0, 0, 0, 0.168)"
                color="gray.800"
                py={5}
                px={5}
                _hover={{
                  bgColor: 'gray.100',
                }}
              >
                <Image src={GoogleLogo} alt="" maxW={5} maxH={5} />
                <Box as="span" fontSize="0.97rem">
                  {t('landing.button')}
                </Box>
              </Button>
            </Flex>
          </GridItem>
          <GridItem
            area="person"
            minW="300px"
            maxW="600px"
            w="32.72vw"
            _dark={{
              filter: 'invert(100%)',
            }}
          >
            <Person style={{ width: '100%', height: '100%' }} />
          </GridItem>
        </Grid>
      </Grid>
    </>
  );
};

export default Landing;
