import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Schedule = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateToNewMeeting = () => {
    navigate('/new-meeting');
  };

  return (
    <>
      <Box>
        <Heading variant="title6" as="h3" mb={4}>
          {t('meeting.new_meeting.title')}
        </Heading>
        <Stack>
          <Card>
            <CardBody>
              <Flex alignItems="center">
                <Button
                  pl={0}
                  variant="ghost"
                  w="100%"
                  justifyContent="start"
                  onClick={navigateToNewMeeting}
                  _hover={{
                    bgColor: 'transparent',
                  }}
                >
                  <Box
                    as="span"
                    borderRadius="5px"
                    p={3}
                    mr={3}
                    backgroundColor="#fee4e7"
                    lineHeight="1"
                  >
                    <AddIcon color="brand.500" />
                  </Box>
                  <Text variant="subtitle3">
                    {t('meeting.new_meeting.new_schedule')}
                  </Text>
                </Button>
              </Flex>
            </CardBody>
          </Card>
        </Stack>
      </Box>
    </>
  );
};

export default Schedule;
