import { UserContext } from 'context/provider/UserProvider';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

type PrivateRouteProps = {
  Component: React.FC;
};

const PrivateRoute = ({ Component }: PrivateRouteProps) => {
  const { state } = useContext(UserContext);
  const { user } = state;

  return user.uid ? <Component /> : <Navigate to="/" />;
};

export default PrivateRoute;
