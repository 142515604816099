import { useState, useEffect } from 'react';
import { axiosInstance } from 'services/axios-client';
import { ContentFile } from 'types/onboarding';

const useOnboarding = () => {
  const [info, setInfo] = useState<ContentFile[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await axiosInstance.get(
          `/info/content-file/category/ONBOARDING`
        );
        setInfo(data.data.contentFiles);
      } catch (error) {}
    };
    getData();
  }, []);

  return { info };
};

export default useOnboarding;
