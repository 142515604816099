import { alertAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  container: {
    borderRadius: '2px',
    justifyContent: 'center',
  },
});

const error = definePartsStyle((props) => {
  const { status } = props;

  return {
    container: {
      bgColor: status === 'error' ? 'brand.200' : 'inherit',
    },
    icon: {
      color: status === 'error' ? 'brand.500' : 'inherit',
    },
  };
});

const variants = { error };

export const alertTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'error',
  },
});
