import { axiosInstance } from 'services/axios-client';
import { AxiosResponse } from 'axios';

const { REACT_APP_API_URL, REACT_APP_OBMEETING_UID } = process.env;

export const getBannerData = async (): Promise<AxiosResponse | undefined> => {
  try {
    return await axiosInstance.get(
      `${REACT_APP_API_URL}/info/content-action/${REACT_APP_OBMEETING_UID}`
    );
  } catch (e) {
    console.log(e);
  }
};
