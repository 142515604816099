export const upperFirst = (str: string): string =>
  `${str?.charAt(0).toUpperCase()}${str?.slice(1)}`;

type ColorDeciderParams =
  | 'PENDING'
  | 'COMPLETED'
  | 'BILLED'
  | 'CANCELLED'
  | 'PROCESSING'
  | 'FAILED';

type ColorCodeStatus = {
  [status: string]: string;
};

export const colorDecider = (status: ColorDeciderParams): string => {
  const colorCodeByStatus: ColorCodeStatus = {
    BILLED: '#219653',
    COMPLETED: '#219653',
    CANCELLED: '#FF285B',
    PENDING: '#67707E',
    DEFAULT: '#000000',
  };

  return colorCodeByStatus[status] || colorCodeByStatus.DEFAULT;
};
