import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { initialState, reducer } from 'context/reducer/UserReducer';
import { GoogleAuthProvider, signInWithPopup, type User } from 'firebase/auth';
import { auth } from 'services/firebase';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from 'services/axios-client';
import { postUserProfile } from 'services/user-profile';
import { UserProfile } from 'types/user';
import { GoogleToken } from 'types/google';

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserContext = createContext({
  state: initialState,
  googleAuth: () => {},
  registerUser: (data: RegisterUserData) => {},
  logout: (redirectPath: string) => {},
});

type RegisterUserData = {
  payload: UserProfile;
  token: GoogleToken;
};

const UserProvider = ({ children }: UserProviderProps) => {
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, initialState);

  const [sessionToken, setSessionToken] = useState<string>('');
  const [tokenExpiration, setTokenExpiration] = useState<string>('');

  const googleAuth = async (): Promise<void> => {
    const provider = new GoogleAuthProvider();
    loadingON();
    try {
      const { user } = await signInWithPopup(auth, provider);

      const { uid, photoURL } = user;

      const token = await user.getIdToken();
      setSessionToken(token);
      localStorage.setItem('token', token);

      localStorage.setItem('photoURL', photoURL ?? '');

      dispatch({
        type: 'GOOGLE_LOGIN_SUCCESS',
        payload: { ...user, token },
      });

      const isRegistered = await axiosInstance.get(`/user-profile/${uid}`);

      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: { ...user, ...isRegistered.data, token },
      });

      if (isRegistered.data) {
        if (isRegistered.data.banned) {
          loadingOFF();
          logout('/?banned=true');
          return;
        }
        if (isRegistered.data.examCompleted) {
          loadingOFF();
          navigate('/dashboard');
        } else {
          loadingOFF();
          navigate('/onboarding');
        }
      }
    } catch (error: unknown) {
      console.error(error);
      // @ts-ignore
      if (error.response.status === 404) {
        loadingOFF();
        navigate('/register');
        return;
      }
    }
  };

  const registerUser = async (data: RegisterUserData): Promise<void> => {
    const { payload, token } = data;
    try {
      const result = await postUserProfile({ payload });

      dispatch({
        type: 'REGISTER_SUCCESS',
        payload: { ...result?.data, token },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: 'REGISTER_FAILED',
      });
    }
  };

  const logout = (redirectPath: string): void => {
    auth.signOut().then(() => {
      localStorage.removeItem('token');
      dispatch({
        type: 'LOGOUT',
      });
      navigate(redirectPath);
    });
  };

  const checkUserState = useCallback(
    (userData: { banned: boolean; examCompleted: boolean }) => {
      if (userData) {
        if (userData.banned) {
          loadingOFF();
          logout('/?banned=true');
          return;
        }
        if (userData.examCompleted) {
          loadingOFF();
          navigate('/dashboard');
        } else {
          loadingOFF();
          navigate('/onboarding');
        }
      }
    },
    []
  );

  const loadingON = () =>
    dispatch({
      type: 'LOADING_ON',
    });

  const loadingOFF = () =>
    dispatch({
      type: 'LOADING_OFF',
    });

  const refreshToken = async (user: User) => user.getIdToken(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setSessionToken(token);

        const tokenInfo = await user.getIdTokenResult();
        const tokenExpirationTimestamp = tokenInfo.expirationTime;
        setTokenExpiration(tokenExpirationTimestamp);

        localStorage.setItem('token', token);
        dispatch({
          type: 'GOOGLE_LOGIN_SUCCESS',
          payload: { ...user, token },
        });

        try {
          const { data } = await axiosInstance.get(`/user-profile/${user.uid}`);
          dispatch({
            type: 'LOGIN_SUCCESS',
            payload: { ...data, token },
          });
          checkUserState(data);
        } catch (error) {
          loadingOFF();
          navigate('/register');
        }
      } else {
        loadingOFF();
        logout('/');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (sessionToken && tokenExpiration) {
        const now = new Date().getTime();
        const expirationTime = new Date(tokenExpiration).getTime();
        const timeUntilExpiration = expirationTime - now;

        if (timeUntilExpiration > 0) {
          const refreshTokenTimeout = setTimeout(() => {
            refreshToken(user as User).then(setSessionToken);
          }, timeUntilExpiration);

          return () => clearTimeout(refreshTokenTimeout);
        } else {
          refreshToken(user as User).then(setSessionToken);
        }
      }
    });

    return () => unsubscribe();
  }, [sessionToken, tokenExpiration]);

  return (
    <UserContext.Provider value={{ state, googleAuth, registerUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};
export default UserProvider;
