import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { getBannerData } from 'services/dashboard';
import { Dashboard } from 'types/dashboard';
import { useTranslation } from 'react-i18next';

export const DashboardContext = createContext<Dashboard>({
  obMeetingBanner: {
    title: '',
    linkLabel: '',
    linkUrl: '',
    message: '',
    activeFrom: '',
    activeTo: '',
  },
});

type ContextProviderProps = { children: ReactNode };

export const DashboardProvider = ({ children }: ContextProviderProps) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const [dashboardData, setDashboardData] = useState<Dashboard>({
    obMeetingBanner: {
      title: '',
      linkLabel: '',
      linkUrl: '',
      message: '',
      activeFrom: '',
      activeTo: '',
    },
  });

  const getBanner = useCallback(async () => {
    const response = await getBannerData();

    if (response?.status === 200) {
      const { data } = response;
      return data;
    }

    return false;
  }, []);

  useEffect(() => {
    const lng = language.substring(0, 2); // remove the country identifier, i.e.: -US

    getBanner().then((bannerData) => {
      if (bannerData) {
        const {
          titleEn,
          titleEs,
          linkLabelEn,
          linkLabelEs,
          linkUrl,
          messageEn,
          messageEs,
          activeFrom,
          activeTo,
        } = bannerData;

        const title: { [lang: string]: string } = {
          en: titleEn,
          es: titleEs,
        };

        const linkLabel: { [lang: string]: string } = {
          en: linkLabelEn,
          es: linkLabelEs,
        };

        const message: { [lang: string]: string } = {
          en: messageEn,
          es: messageEs,
        };

        setDashboardData((prevDashboardData) => ({
          ...prevDashboardData,
          obMeetingBanner: {
            title: title[lng],
            linkLabel: linkLabel[lng],
            linkUrl,
            message: message[lng],
            activeFrom,
            activeTo,
          },
        }));
      }
    });
  }, [language]);

  return (
    <DashboardContext.Provider value={dashboardData}>
      {children}
    </DashboardContext.Provider>
  );
};
