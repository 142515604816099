import { object, string } from 'yup';

const meetingClientInfoSchema = object({
  company: string()
    .max(255, 'global.forms.errors.max_characters_255')
    .required('global.forms.errors.required'),
  business: string()
    .max(255, 'global.forms.errors.max_characters_255')
    .required('global.forms.errors.required'),
  employeeCount: string()
    .matches(/^[0-9]+$/, {
      message: 'global.forms.errors.invalid_format',
    })
    .required('global.forms.errors.required'),
  acquaintanceName: string()
    .max(255, 'global.forms.errors.max_characters_255')
    .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s']+$/g, {
      message: 'global.forms.errors.invalid_format',
    })
    .required('global.forms.errors.required'),
  acquaintancePosition: string()
    .max(255, 'global.forms.errors.max_characters_255')
    .required('global.forms.errors.required'),
  acquaintanceLinkedinProfile: string()
    .max(1000, 'global.forms.errors.max_characters_1000')
    .required('global.forms.errors.required'),
});

export default meetingClientInfoSchema;
