import { mixed, object, string } from 'yup';
import countries from '../views/ProfileRegister/countries';

const profileRegisterSchema = object({
  firstName: string()
    .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s']+$/g, {
      message: 'profile_registration.form_fields.errors.invalid_characters',
    })
    .max(255, 'global.forms.errors.max_characters_255')
    .required('profile_registration.form_fields.errors.required'),
  lastName: string()
    .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s']+$/g, {
      message: 'profile_registration.form_fields.errors.invalid_characters',
    })
    .max(255, 'global.forms.errors.max_characters_255')
    .required('profile_registration.form_fields.errors.required'),
  payoneerEmail: string()
    .email('profile_registration.form_fields.errors.invalid_format')
    .max(255, 'global.forms.errors.max_characters_255')
    .required('profile_registration.form_fields.errors.required'),
  phoneNumber: string()
    .matches(/^[0-9]+$/, {
      message: 'profile_registration.form_fields.errors.invalid_format',
    })
    .max(255, 'global.forms.errors.max_characters_255')
    .required('profile_registration.form_fields.errors.required'),
  country: mixed()
    .oneOf(countries, 'profile_registration.form_fields.errors.invalid_option')
    .required('profile_registration.form_fields.errors.required'),
  linkedinUrl: string()
    .max(1000, 'global.forms.errors.max_characters_1000')
    .required('profile_registration.form_fields.errors.required'),
});

export default profileRegisterSchema;
