import React, { ReactNode } from 'react';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  Progress,
  Tooltip,
} from '@chakra-ui/react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/all';
import { useTranslation } from 'react-i18next';

export type StepperProps = {
  onPreviousStep: () => unknown;
  onNextStep: () => unknown;
  prevButtonProps?: ButtonProps;
  nextButtonProps?: ButtonProps;
  prevButtonText?: string;
  nextButtonText?: string;
  currentStep: number;
  length: number;
  children: ReactNode;
} & BoxProps;

export default function Stepper({
  onPreviousStep,
  onNextStep,
  prevButtonProps,
  nextButtonProps,
  prevButtonText,
  nextButtonText,
  currentStep,
  length,
  children,
  ...ChakraBoxProps
}: StepperProps) {
  const { t } = useTranslation();

  const getProgressValue = () =>
    length ? (currentStep / (length - 1)) * 100 : 0;

  return (
    <Box {...ChakraBoxProps}>
      {children}
      <Flex
        w="100%"
        align="center"
        justify="space-between"
        gap={{ sm: 5, md: 10, lg: 20 }}
        mt={16}
      >
        <Tooltip
          hasArrow
          arrowPadding={55}
          label={`${t('stepper.tooltip_text', {
            initial: currentStep + 1,
            total: length,
          })}`}
          placement="top-start"
        >
          <Progress
            value={getProgressValue()}
            size="xs"
            colorScheme="pink"
            w="80%"
          />
        </Tooltip>
        <Flex gap={30}>
          <Button
            type="button"
            leftIcon={<FaArrowLeft />}
            my={29}
            w="max-content"
            variant="ghost"
            onClick={onPreviousStep}
            isDisabled={currentStep <= 0}
            {...prevButtonProps}
          >
            {prevButtonText || t('global.back')}
          </Button>
          <Button
            type="button"
            rightIcon={<FaArrowRight />}
            my={29}
            w="max-content"
            onClick={onNextStep}
            {...nextButtonProps}
          >
            {nextButtonText || t('global.next')}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
