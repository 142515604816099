import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    bg: 'gray.50',
    borderRadius: '16px',
    boxShadow:
      '0px 0px 2px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24);',
    _dark: {
      bg: 'gray.800',
    },
  },
});

export const cardTheme = defineMultiStyleConfig({ baseStyle });
