import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, useDisclosure } from '@chakra-ui/react';
import NewMeetingForm from 'views/NewMeeting/NewMeetingForm';
import {
  postMeetingClientInfo,
  putMeetingCalendlyEventURI,
  putMeetingClientInfo,
} from 'services/meeting';
import { UserContext } from 'context/provider/UserProvider';
import { MeetingPayload } from 'types/meeting';
import NewMeetingCalendly from 'views/NewMeeting/NewMeetingCalendly';
import { CalendlyWidgetProps } from 'components/Calendly/CalendlyWidget/CalendlyWidget';
import { EventScheduledEvent } from 'react-calendly';
import { useNavigate } from 'react-router-dom';
import Stepper from 'components/Stepper/Stepper';
import { isEmpty } from 'utils/object';
import Dialog, { type DialogProps } from 'components/shared/Dialog';

const STEPS_LENGTH = 2;

const EXCEPTIONS_PREFIX = 'co.coderio.prospectors.exceptions.';
const ERROR_TYPES = {
  duplicatedLinkedin: 'DuplicatedAcquaintanceException',
};
Object.freeze(ERROR_TYPES);

type StepOneProps = {
  onChange: (values: Partial<MeetingPayload>) => unknown;
  onReadyStateChange: (valid: boolean) => unknown;
  prefill?: Partial<MeetingPayload>;
};

const StepOne = ({ onChange, onReadyStateChange, prefill }: StepOneProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading as="h2" variant="title4">
        {t('meeting.new_meeting.title')}
      </Heading>
      <Box maxW="715px">
        <NewMeetingForm
          onReadyStateChange={onReadyStateChange}
          onChange={onChange}
          prefill={prefill}
        />
      </Box>
    </>
  );
};

type StepTwoProps = CalendlyWidgetProps;

const StepTwo = ({ onSuccess }: StepTwoProps) => {
  return <NewMeetingCalendly onSuccess={onSuccess} />;
};

export default function NewMeeting() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {
      user: { uid: uuid },
    },
  } = useContext(UserContext);
  const {
    isOpen: isOpenErrorDialog,
    onOpen: onOpenErrorDialog,
    onClose,
  } = useDisclosure();

  const [currentStep, setCurrentStep] = useState(0);
  const [muid, setMuid] = useState<null | number>(null);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(false);
  const [payload, setPayload] = useState<Partial<MeetingPayload>>({});
  const [clientInfo, setClientInfo] = useState<Partial<MeetingPayload>>({});
  const [eventScheduled, setEventScheduled] = useState(false);
  const [dialogProps, setDialogProps] = useState<
    Pick<DialogProps, 'title' | 'description' | 'buttonText'>
  >({
    title: '',
    description: '',
    buttonText: '',
  });

  const duplicatedLinkedinError = `${EXCEPTIONS_PREFIX}${ERROR_TYPES.duplicatedLinkedin}`;

  const handleSubmitStepOne = async () => {
    try {
      if (uuid) {
        const data = { ...payload, userProfileUid: uuid };
        let response;

        if (muid) {
          response = await putMeetingClientInfo({ uid: muid, payload: data });
        } else {
          response = await postMeetingClientInfo({ payload: data });
        }

        if (response?.status !== 200) {
          if (response.data.type === duplicatedLinkedinError) {
            setDialogProps(() => ({
              ...dialogProps,
              title: `${t('meeting.errors.duplicated_linkedin.title')}`,
              description: `${t(
                'meeting.errors.duplicated_linkedin.description'
              )}`,
              buttonText: `${t('global.goto_dashboard')}`,
            }));
          } else {
            setDialogProps(() => ({
              ...dialogProps,
              title: `${t('global.errors.server_error.title')}`,
              description: `${t('global.errors.server_error.description')}`,
              buttonText: `${t('global.goto_dashboard')}`,
            }));
          }
          onOpenErrorDialog();
        } else {
          setMuid(response.data.uid);
          setClientInfo(response.data);
          setCurrentStep(1);
          setNextButtonDisabled(true);
        }
      }
    } catch (e) {
      throw new Error((e as Error).message);
    }
  };

  const handleSuccessStepTwo = async (event: EventScheduledEvent) => {
    try {
      if (event.data.event === 'calendly.event_scheduled') {
        if (muid) {
          const response = await putMeetingCalendlyEventURI({
            uid: muid,
            payload: {
              eventUuid: event.data.payload.event.uri,
            },
          });

          if (response?.status !== 200) {
            onOpenErrorDialog();
          } else {
            setEventScheduled(true);
          }
        }
      }
    } catch (e) {
      throw new Error((e as Error).message);
    }
  };

  const navigateToDashboard = () => navigate('/dashboard');

  const handleGoBackToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep((step) => step - 1);
    } else {
      setDialogProps(() => ({
        ...dialogProps,
        title: `${t('global.warning.changes_lost.title')}`,
        description: `${t('global.warning.changes_lost.description')}`,
        buttonText: `${t('global.goto_dashboard')}`,
      }));
      onOpenErrorDialog();
    }
  };

  const getNextStepAction = () =>
    eventScheduled ? navigateToDashboard : handleSubmitStepOne;

  const getPrevButtonProps = () => ({
    isDisabled: prevButtonDisabled,
  });

  const getNextButtonProps = () => ({ isDisabled: nextButtonDisabled });

  const getPrevButtonText = () =>
    currentStep === 0 ? `${t('global.cancel')}` : `${t('global.back')}`;

  const getNextButtonText = () =>
    eventScheduled ? `${t('global.goto_dashboard')}` : `${t('global.next')}`;

  const handleConfirm = () => {
    navigateToDashboard();
  };

  const getStepperMarginTop = () => (currentStep === 0 ? '3vmax' : 0);

  useEffect(() => {
    if (muid && currentStep === 0 && !isEmpty(clientInfo)) {
      setNextButtonDisabled(false);
    }
  }, [muid, currentStep, clientInfo]);

  useEffect(() => {
    if (eventScheduled && currentStep === 1) {
      setPrevButtonDisabled(true);
      setNextButtonDisabled(false);
    }
  }, [eventScheduled, currentStep]);

  return (
    <>
      <Stepper
        currentStep={currentStep}
        onPreviousStep={handleGoBackToPreviousStep}
        onNextStep={getNextStepAction()}
        prevButtonProps={getPrevButtonProps()}
        nextButtonProps={getNextButtonProps()}
        prevButtonText={getPrevButtonText()}
        nextButtonText={getNextButtonText()}
        length={STEPS_LENGTH}
        mt={getStepperMarginTop()}
      >
        {currentStep === 0 ? (
          <StepOne
            onReadyStateChange={
              muid && currentStep === 0 && !isEmpty(clientInfo)
                ? () => false
                : setNextButtonDisabled
            }
            onChange={setPayload}
            {...(!isEmpty(clientInfo) ? { prefill: clientInfo } : {})}
          />
        ) : (
          <StepTwo onSuccess={handleSuccessStepTwo} />
        )}
      </Stepper>
      <Dialog
        onConfirm={handleConfirm}
        withCancelButton
        onClose={onClose}
        isOpen={isOpenErrorDialog}
        {...dialogProps}
      />
    </>
  );
}
