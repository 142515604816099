import { axiosInstance } from 'services/axios-client';

const { REACT_APP_API_URL } = process.env;

type GetMetricsParams = { uid: string };

export const getMetrics = async ({ uid }: GetMetricsParams) => {
  try {
    return await axiosInstance.get(
      `${REACT_APP_API_URL}/user-profile/${uid}/metrics`
    );
  } catch (e) {
    console.log(e);
  }
};
