import React, { ReactNode } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

export type FieldErrorMessageProps = TextProps & {
  text: string;
  icon?: ReactNode;
};

export default function FieldErrorMessage({
  text,
  icon,
  ...ChakraTextProps
}: FieldErrorMessageProps) {
  return (
    <Text
      display="flex"
      alignItems="center"
      gap={1}
      mt={1.5}
      lineHeight="normal"
      textAlign="start"
      {...ChakraTextProps}
    >
      {icon}
      {text}
    </Text>
  );
}
