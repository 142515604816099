import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/all';
import { getConfidentialityText } from 'services/user-agreement';
import HTMLParser from 'components/shared/HTMLParser';
import { useTranslation } from 'react-i18next';

const ConfidentialityAgreementText = () => {
  const { i18n } = useTranslation();
  const [NDAText, setNDAText] = useState('');

  const getContentByLanguage = useCallback(
    () => (i18n.language === 'en' ? 'contentEn' : 'contentEs'),
    []
  );

  const getNDAText = useCallback(
    async () => await getConfidentialityText(),
    []
  );

  useEffect(() => {
    getNDAText().then((response) =>
      setNDAText(response?.data?.[getContentByLanguage()])
    );
  }, []);

  return (
    <Box
      textAlign="center"
      sx={{
        textAlign: 'start',
        fontSize: '0.875rem',
        h1: { fontSize: '1.25rem', textAlign: 'center' },
        h2: { fontSize: 'initial', textAlign: 'center' },
        p: { my: 2 },
      }}
    >
      <HTMLParser htmlString={NDAText} />
    </Box>
  );
};

type ConfidentialityAgreementModalProps = {
  isOpen: boolean;
  onClose: () => unknown;
  onDecline: () => unknown;
  onAccept: () => unknown;
};

export default function ConfidentialityAgreementModal({
  isOpen,
  onClose,
  onDecline,
  onAccept,
}: ConfidentialityAgreementModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent p={5} pb={20}>
        <ModalBody>
          <ConfidentialityAgreementText />
        </ModalBody>
        <ModalFooter
          bg="gray.100"
          pos="absolute"
          left={0}
          bottom={0}
          boxShadow="0px -1px 9px 0px rgba(0, 0, 0, 0.50)"
          w="100%"
        >
          <Button
            variant="ghost"
            leftIcon={<FaArrowLeft />}
            onClick={onDecline}
          >
            {t('global.decline')}
          </Button>
          <Button rightIcon={<FaArrowRight />} onClick={onAccept}>
            {t('global.accept')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
