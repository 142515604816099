import React, { ChangeEvent, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleMeetingCancel: (uid: string | null, reason: string) => void;
};

const CancelMeetingModal = ({
  isOpen,
  onClose,
  handleMeetingCancel,
}: Props) => {
  const [reason, setReason] = useState<string>('');
  const meetingId = localStorage.getItem('meetingId');

  const { t } = useTranslation();

  const handleMeetingCancelFn = () => {
    //cancelMeeting({ uid: meetingId, reason });
    handleMeetingCancel(meetingId, reason);
    onClose();
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent minH="250px">
          <ModalHeader>{t('meeting.cancel_title')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text variant="caption1" marginBottom="15px">
                {t('meeting.cancel_quest')}
              </Text>
            </Box>
            <Text marginBottom="8px" variant="caption2">
              {t('meeting.cancel_reason')}
            </Text>
            <Textarea
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setReason(e.target.value)
              }
              // placeholder="Write the reason"
              size="md"
              resize="vertical"
              boxShadow="base"
              p="1"
              rounded="md"
              bg="white"
              required
            />
          </ModalBody>

          <ModalFooter gap={4}>
            <Button onClick={onClose} variant="ghost">
              <ChevronLeftIcon fontSize="25px" />
              {t('meeting.cancel_back')}
            </Button>
            <Button onClick={handleMeetingCancelFn}>
              {t('meeting.cancel_continue')}
              <ChevronRightIcon fontSize="25px" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancelMeetingModal;
