import { Box, HStack, Image, Text, Link } from '@chakra-ui/react';
import React from 'react';
import { ContentFile } from 'types/onboarding';
import onboardingCardImage from '../../assets/img/onboarding-pic.png';

type Props = {
  index: number;
  lang: string;
  info: ContentFile;
};

enum Lang {
  ES = 'es',
  EN = 'en',
}

const OnboardingCard = ({ index, lang, info }: Props) => {
  if (index % 2 === 0) {
    return (
      <HStack
        alignItems="center"
        mb="70px"
        justifyContent="center"
        width="100%"
      >
        <Box display="flex" justifyContent="end" width="36%" mr="4.3%">
          <Link href={lang === Lang.EN ? info?.urlEn : info?.urlEs} isExternal>
            <Image
              // boxSize="261px"
              // height="168px"
              objectFit="contain"
              //  src={info?.imageUrlEn}
              //  src={lang === Lang.EN ? info?.imageUrlEn : info?.imageUrlEs}
              src={onboardingCardImage}
              alt="onboarding-image"
              mr="28px"
              aspectRatio="4/3"
              width="100%"
            />
          </Link>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="60%"
        >
          <Box>
            <Text variant="subtitle1" fontWeight="700px" mb="15px">
              {/* About Coderio */}
              {lang === Lang.EN ? info?.titleEn : info?.titleEs}
            </Text>
            <Text variant="caption1" width="436px">
              {/* Learn more about the company and the way we work. */}
              {lang === Lang.EN ? info?.descriptionEn : info?.descriptionEs}
            </Text>
          </Box>
        </Box>
      </HStack>
    );
  } else {
    return (
      <HStack alignItems="center" justifyContent="center" width="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="57%"
          mr="7.3%"
        >
          <Box>
            <Text variant="subtitle1" fontWeight="700px" mb="15px">
              {lang === Lang.EN ? info?.titleEn : info?.titleEs}
            </Text>
            <Text variant="caption1">
              {lang === Lang.EN ? info?.descriptionEn : info?.descriptionEs}
            </Text>
          </Box>
        </Box>
        <Box display="flex" justifyContent="end" width="36%">
          <Link href={lang === Lang.EN ? info?.urlEn : info?.urlEs} isExternal>
            <Image
              // boxSize="261px"
              //  height="168px"
              objectFit="contain"
              //  src={lang === Lang.EN ? info?.imageUrlEn : info?.imageUrlEs}
              src={onboardingCardImage}
              alt="onboarding-image"
              //  src={info?.imageUrlEn}
              aspectRatio="4/3"
              width="100%"
            />
          </Link>
        </Box>
      </HStack>
    );
  }
};

export default OnboardingCard;
