import React, { useContext, useEffect, useState } from 'react';
import { DashboardContext } from 'context/provider/DashboardProvider';
import { Alert, Button, Heading, Link, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function OBMeetingBanner() {
  const { t } = useTranslation();
  const { obMeetingBanner } = useContext(DashboardContext);
  const [showMeetingLinkButton, setShowMeetingLinkButton] =
    useState<boolean>(false);

  const linkButtonStyles = {
    alignSelf: 'end',
    borderRadius: '8px',
    display: 'block',
    mt: 4,
    py: 1,
    px: 3,
  };

  useEffect(() => {
    const now = new Date().getTime();
    const from = new Date(obMeetingBanner.activeFrom).getTime();
    const to = new Date(obMeetingBanner.activeTo).getTime();

    const isBetweenMeetingTimeRange = now >= from && now <= to;

    const timeUntilMeetingStarts = from - now;
    const timeUntilMeetingEnds = to - now;

    const hasNullDates = [
      obMeetingBanner.activeFrom,
      obMeetingBanner.activeTo,
    ].some((val) => !val);

    setShowMeetingLinkButton(isBetweenMeetingTimeRange || hasNullDates);

    if (timeUntilMeetingStarts > 0) {
      const showLinkButtonWhenMeetingStarts = setTimeout(
        () => setShowMeetingLinkButton(true),
        timeUntilMeetingStarts
      );
      return () => clearTimeout(showLinkButtonWhenMeetingStarts);
    } else {
      const meetingLinkTimeout = setTimeout(
        () => setShowMeetingLinkButton(false),
        timeUntilMeetingEnds
      );
      return () => clearTimeout(meetingLinkTimeout);
    }
  }, [obMeetingBanner, showMeetingLinkButton]);

  const emptyBanner = Object.values(obMeetingBanner).every((val) => !val);

  return emptyBanner ? (
    <></>
  ) : (
    <Alert
      bgColor="brand.50"
      borderRadius="16px"
      flexDir="column"
      alignItems="start"
      gap={2}
      boxShadow="0px 16px 32px -4px rgba(145, 158, 171, 0.24), 0px 0px 2px 0px rgba(145, 158, 171, 0.24)"
      p={5}
    >
      <Heading
        as="h3"
        variant="title6"
        color="gray.800"
        fontSize="1.3125rem"
        textAlign="start"
        m={0}
      >
        {obMeetingBanner.title}
      </Heading>
      <Text fontSize="1.0625rem" variant="caption1" color="gray.800">
        {`${obMeetingBanner.message} `}
      </Text>
      {showMeetingLinkButton ? (
        <Link
          isExternal
          href={obMeetingBanner.linkUrl}
          bgColor="brand.500"
          color="gray.100"
          {...linkButtonStyles}
        >
          {obMeetingBanner.linkLabel}
        </Link>
      ) : (
        <Tooltip
          label={`${t('dashboard.ob_meeting_banner.link_tooltip')}`}
          hasArrow
          arrowPadding={80}
          placement="top-end"
          bg="gray.100"
          p={2}
          color="gray.800"
          maxW="163px"
          textAlign="center"
          borderRadius="4px"
          boxShadow="0 0 8px 1px rgba(0, 0, 0, 0.17)"
        >
          <Button isDisabled {...linkButtonStyles}>
            {obMeetingBanner.linkLabel}
          </Button>
        </Tooltip>
      )}
    </Alert>
  );
}
