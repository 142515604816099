import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from 'routes/private/PrivateRoute';
import Dashboard from 'views/Dashboard/Dashboard';
import MainLayout from 'routes/layout/MainLayout';
import Landing from 'views/Landing/Landing';
import Onboarding from 'views/Onboarding/Onboarding';
import UserProvider from 'context/provider/UserProvider';
import ProfileRegister from 'views/ProfileRegister/ProfileRegister';
import Exam from 'views/Exam/Exam';
import NewMeeting from 'views/NewMeeting/NewMeeting';
import ProfileEdit from 'views/ProfileEdit/ProfileEdit';

const MainRoutes = () => {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Landing />} />
            <Route path="register" element={<ProfileRegister />} />
            <Route
              path="dashboard"
              element={<PrivateRoute Component={Dashboard} />}
            />
            <Route
              path="onboarding"
              element={<PrivateRoute Component={Onboarding} />}
            />
            <Route path="exam" element={<PrivateRoute Component={Exam} />} />
            <Route
              path="new-meeting"
              element={<PrivateRoute Component={NewMeeting} />}
            />
            <Route
              path="profile-edit"
              element={<PrivateRoute Component={ProfileEdit} />}
            />
          </Route>
        </Routes>
      </UserProvider>
    </Router>
  );
};

export default MainRoutes;
