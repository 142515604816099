import * as React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { customTheme } from 'theme';
import Routes from './routes';

const App = () => (
  <ChakraProvider theme={customTheme}>
    <Routes />
  </ChakraProvider>
);

export default App;
