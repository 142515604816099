import { Box, HStack, Text } from '@chakra-ui/react';
import { MetricsDataset } from 'components/Metrics/MetricsDisplay';
import { useTranslation } from 'react-i18next';

type LeftSiteTextComponentProps = {
  title: string;
  count: number;
  withBorder: boolean;
};

const MetricCardLeftSide = ({
  weekCount,
  monthCount,
}: Pick<MetricsDataset, 'weekCount' | 'monthCount'>): JSX.Element => {
  const { t } = useTranslation();
  return (
    <HStack spacing={4}>
      <TextComponent
        title={t('metrics.months')}
        count={monthCount}
        withBorder={true}
      />
      <TextComponent
        title={t('metrics.weeks')}
        count={weekCount}
        withBorder={true}
      />
    </HStack>
  );
};
export default MetricCardLeftSide;

const TextComponent = ({
  withBorder,
  title,
  count,
}: LeftSiteTextComponentProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box
      pr={withBorder ? 4 : 0}
      borderRight={withBorder ? '1px solid #BDBDBD' : ''}
    >
      <Text variant="caption1">{title}</Text>
      <Text variant="caption1" color="brand.500">
        {count} {t('metrics.meets')}
      </Text>
    </Box>
  );
};
