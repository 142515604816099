import { Card, Flex, Heading, Stack } from '@chakra-ui/react';
import MetricCardHeader from './MetricCardHeader';
import MetricCardLeftSide from './MetricCardLeftSide';
import MetricCardRightSide from 'components/Metrics/MetricCardRightSide';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getMetrics } from 'services/metrics';
import { UserContext } from 'context/provider/UserProvider';
import { useTranslation } from 'react-i18next';
import { Meeting } from 'types/meeting';

export type MetricsDataset = {
  level: number;
  weekCount: number;
  monthCount: number;
  pendingCount: number;
  completedCount: number;
  billedCount: number;
  cancelledCount: number;
};

type Props = {
  meetings: Meeting[];
};

const MetricsDisplay = ({ meetings }: Props): JSX.Element => {
  const { state } = useContext(UserContext);
  const { t } = useTranslation();
  const [dataset, setDataset] = useState<MetricsDataset>();

  const getMetricsData = useCallback(async () => {
    const {
      user: { uid },
    } = state;
    return getMetrics({ uid });
  }, []);

  useEffect(() => {
    getMetricsData().then((response) => setDataset({ ...response?.data }));
  }, [meetings]);

  return (
    <>
      <Heading as="h3" variant="title6" mb={4}>
        {t('metrics.metrics_title')}
      </Heading>
      <Card pt="26px" pb="47px" px="25px">
        <Stack spacing={2}>
          <Flex gap={2} justify="space-between" align="center" wrap="wrap">
            <Stack>
              <MetricCardHeader level={dataset?.level as number} />
              <MetricCardLeftSide
                monthCount={dataset?.monthCount as number}
                weekCount={dataset?.weekCount as number}
              />
            </Stack>

            <MetricCardRightSide dataset={dataset} />
          </Flex>
        </Stack>
      </Card>
    </>
  );
};

export default MetricsDisplay;
