import React from 'react';
import { Flex, Radio } from '@chakra-ui/react';

export type RadioCardProps = { value: string; label: string };

export default function RadioCard({ value, label }: RadioCardProps) {
  return (
    <Flex
      align="center"
      bg="gray.100"
      boxShadow="0px 0px 2.33043px rgba(0, 0, 0, 0.084), 0px 1.55362px 2.33043px rgba(0, 0, 0, 0.168)"
      borderRadius="4px"
      p="11.6522px"
      _dark={{
        bg: 'gray.700',
        boxShadow:
          '0px 0px 2.33043px rgba(255, 255, 255, 0.084), 0px 1.55362px 2.33043px rgba(255, 255, 255, 0.168)',
      }}
    >
      <Radio value={value} w="100%" h="100%">
        {label}
      </Radio>
    </Flex>
  );
}
