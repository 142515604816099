import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as OnboardingPerson } from 'assets/img/onboarding-person.svg';
import { useTranslation } from 'react-i18next';
import useOnboarding from 'hooks/useOnboarding';
import i18n from 'i18n';
import OnboardingCard from 'components/OnboardingCard/OnboardingCard';

const Onboarding = () => {
  const { t } = useTranslation();
  const { info } = useOnboarding();
  const lang = i18n.language;
  const navigate = useNavigate();

  return (
    <>
      <Grid
        alignItems="center"
        as="section"
        autoFlow="column"
        justifyItems="center"
        // minH="calc(100vh - 107px)"
        w="100%"
      >
        <Grid
          m={0}
          templateColumns={{ md: '1fr 1fr', lg: '1.5fr 1fr' }}
          templateAreas={{
            base: `
              "person"
              "sign-in"  
            `,
            md: `
              "sign-in person"
            `,
          }}
          gap={6}
          maxW="1368px"
          alignItems="center"
          justifyItems="center"
          w="100%"
        >
          <GridItem
            area="sign-in"
            display="flex"
            flexDir="column"
            justifyContent="center"
            gap={4}
            maxW="40rem"
          >
            <Heading as="h3" variant="title4">
              {t('onboarding.title')}
            </Heading>
            <Text variant="caption1" colorScheme="gray.700">
              {t('onboarding.description')}
            </Text>
          </GridItem>
          <GridItem
            area="person"
            minW="300px"
            maxW="600px"
            w="32.72vw"
            _dark={{
              filter: 'invert(100%)',
            }}
          >
            <OnboardingPerson />
          </GridItem>
        </Grid>
      </Grid>
      <Box w="55%" margin="auto" mb="150px">
        <Stack justifyContent="center">
          {info.length > 0 &&
            info.map((i, index) => (
              <OnboardingCard key={index} index={index} info={i} lang={lang} />
            ))}
        </Stack>
      </Box>

      <Box width="fit-content" mx="auto" pb="50px">
        <Text variant="subtitle1" fontWeight="700" textAlign="center" mb="38px">
          {t('onboarding.ready')}
        </Text>
        <Button
          colorScheme="red"
          width="178px"
          height="48px"
          onClick={() => navigate('/exam')}
        >
          {t('onboarding.button')}
        </Button>
      </Box>
    </>
  );
};

export default Onboarding;
