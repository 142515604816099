import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  CheckboxGroup,
  Flex,
  Heading,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import RadioCard from 'components/RadioCard/RadioCard';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'context/provider/UserProvider';
import { getExam, putExam } from 'services/exam';
import { type Exam as ExamProps, type ExamPayload } from 'types/exam';
import { upperFirst } from 'utils/string';
import CheckboxCard from 'components/CheckboxCard/CheckboxCard';
import Loader from 'components/Loader/Loader';
import Dialog from 'components/shared/Dialog';
import { useNavigate } from 'react-router-dom';
import Stepper from 'components/Stepper/Stepper';

const EXAM_UID = Number(process.env.REACT_APP_EXAM_UID);

export default function Exam(): JSX.Element {
  const [exam, setExam] = useState<ExamProps>();
  const { state } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isOpen: isOpenExamCompletedDialog,
    onOpen: onOpenExamCompletedDialog,
  } = useDisclosure();
  const { i18n } = useTranslation();

  const [value, setValue] = useState<string | (string | number)[]>('');
  const [payload, setPayload] = useState<ExamPayload>({ questions: [] });
  const [step, setStep] = useState(0);
  const [approved, setApproved] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [examCompletedDialog, setExamCompletedDialog] = useState({
    title: '',
    text: '',
    buttonText: '',
  });

  const loadExam = useCallback(async () => {
    return getExam({ uid: EXAM_UID });
  }, [state]);

  const isLastStep = () => exam && step === exam.questions.length - 1;

  const goToNextStep = () => {
    if (!isLastStep()) setStep(step + 1);
  };

  const goToPrevStep = () => {
    if (step > 0) setStep(step - 1);
    if (step === 0) navigate('/onboarding');
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const getExamLength = () => exam?.questions?.length || 0;

  const handleDismissDialog = () => {
    if (approved) {
      navigate('/dashboard');
    } else {
      navigate('/onboarding');
    }
  };

  const validateExam = async () => {
    setIsValidating(true);

    const response = await putExam({
      uid: state.user.uid,
      examUid: EXAM_UID,
      payload,
    });

    setApproved(response?.data?.examCompleted || false);
    setIsValidating(false);
    onOpenExamCompletedDialog();
  };

  const getDescriptionByLanguage = () =>
    i18n.language === 'en' ? 'descriptionEn' : 'descriptionEs';

  useEffect(() => {
    loadExam().then((response) =>
      setExam({ questions: response?.data?.questions })
    );
  }, []);

  useEffect(() => {
    if (exam) {
      if (exam?.questions[step].isMultiple) {
        setValue([]);
      } else {
        setValue('');
      }
    }
  }, [exam]);

  useEffect(() => {
    if (payload?.questions[step]?.answers.length) {
      if (exam?.questions[step].isMultiple) {
        setValue(
          payload.questions[step].answers.map(
            (item) => `${Object.values(item)}`
          )
        );
      } else {
        setValue(`${payload?.questions[step]?.answers[0]?.uid}`);
      }
    } else {
      if (exam?.questions[step].isMultiple) {
        setValue([]);
      } else {
        setValue('');
      }
    }
  }, [step]);

  useEffect(() => {
    if (value && exam) {
      const answer = exam?.questions[step]?.answers.find(
        ({ uid }) => uid === Number(value)
      );

      const payloadAnswer = { ...payload };

      payloadAnswer.questions[step] = {
        uid: exam?.questions[step]?.uid,
        answers: [],
      };

      if (Array.isArray(value)) {
        payloadAnswer.questions[step].answers = value.map((answerUid) => ({
          uid: Number(answerUid),
        }));
      } else {
        payloadAnswer.questions[step].answers = [
          {
            uid: answer?.uid as number,
          },
        ];
      }

      setPayload(payloadAnswer);
    }
  }, [value]);

  useEffect(() => {
    if (!approved) {
      setExamCompletedDialog(() => ({
        title: t('exam.dialog.failed.title'),
        text: t('exam.dialog.failed.description'),
        buttonText: t('exam.dialog.failed.button_text'),
      }));
    } else {
      setExamCompletedDialog(() => ({
        title: t('exam.dialog.successful.title'),
        text: t('exam.dialog.successful.description'),
        buttonText: t('exam.dialog.successful.button_text'),
      }));
    }
  }, [approved]);

  return (
    <Box mt="3vmax">
      <Box>
        <Heading as="h2" variant="title5" mb={2.5}>
          {t('exam.title')}
        </Heading>
        <Text variant="caption1">{t('exam.subtitle')}</Text>
      </Box>
      <Flex
        flexDir="column"
        justify={{ sm: 'center', lg: 'initial' }}
        gap={35}
        mt={20}
      >
        <Heading as="h3" variant="title6" fontWeight={500}>
          {upperFirst(`${exam?.questions[step]?.[getDescriptionByLanguage()]}`)}
        </Heading>
        <form onSubmit={handleSubmit}>
          <Stepper
            onPreviousStep={goToPrevStep}
            onNextStep={!isLastStep() ? goToNextStep : validateExam}
            prevButtonProps={{ isDisabled: isValidating }}
            nextButtonProps={{
              type: isLastStep() ? 'submit' : 'button',
              isDisabled: !value.length || isValidating,
            }}
            currentStep={step}
            length={getExamLength()}
          >
            {exam?.questions[step]?.isMultiple ? (
              <CheckboxGroup onChange={setValue} value={value as string[]}>
                <Stack
                  direction="column"
                  spacing="35px"
                  maxW={{ sm: 'none', lg: '60%', xl: '40%' }}
                >
                  {exam?.questions[step]?.answers?.map((question) => (
                    <CheckboxCard
                      key={question.uid}
                      value={`${question.uid}`}
                      label={upperFirst(
                        `${question[getDescriptionByLanguage()]}`
                      )}
                    />
                  ))}
                </Stack>
              </CheckboxGroup>
            ) : (
              <RadioGroup
                onChange={setValue}
                value={`${value}`}
                maxW={{ sm: 'none', lg: '60%', xl: '40%' }}
              >
                <Stack direction="column" spacing="35px">
                  {exam?.questions[step]?.answers?.map((question) => (
                    <RadioCard
                      key={question.uid}
                      value={`${question.uid}`}
                      label={upperFirst(
                        `${question[getDescriptionByLanguage()]}`
                      )}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            )}
          </Stepper>
        </form>
      </Flex>
      <Loader isOpen={isValidating} />
      <Dialog
        title={examCompletedDialog.title}
        description={examCompletedDialog.text}
        buttonText={examCompletedDialog.buttonText}
        onClose={handleDismissDialog}
        onConfirm={handleDismissDialog}
        isOpen={isOpenExamCompletedDialog}
      />
    </Box>
  );
}
