import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  Link,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { getDashboardFiles } from 'services/content-file';
import { useTranslation } from 'react-i18next';
import { DashboardFile } from 'types/content-file';
import FileThumbnail from 'assets/img/onboarding-pic.png';

export default function UsefulResources() {
  const { i18n, t } = useTranslation();
  const [usefulResources, setUsefulResources] = useState<DashboardFile[]>([]);

  const getContentByLanguage = (): {
    title: string;
    description: string;
    url: string;
  } =>
    i18n.language === 'en'
      ? {
          title: 'titleEn',
          description: 'descriptionEn',
          url: 'urlEn',
        }
      : {
          title: 'titleEs',
          description: 'descriptionEs',
          url: 'urlEs',
        };

  const getUsefulResources = useCallback(
    async () => await getDashboardFiles(),
    []
  );

  useEffect(() => {
    getUsefulResources().then((response) =>
      setUsefulResources(response?.data?.contentFiles)
    );
  }, []);

  return (
    <Box as="section">
      <Heading variant="title6" as="h3" mb={4}>
        {t('dashboard.useful_information')}
      </Heading>
      <Card>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            {usefulResources.map((item) => (
              <Flex key={item.uid} align="center" gap={3}>
                <Link
                  aspectRatio="16/9"
                  w="6rem"
                  h="3.6875rem"
                  bgImage={`url(${FileThumbnail})`}
                  bgSize="cover"
                  href={item[getContentByLanguage().url]}
                  download
                  title={item[getContentByLanguage().description]}
                  target="_blank"
                ></Link>
                <Text variant="caption1">
                  {item[getContentByLanguage().title]}
                </Text>
              </Flex>
            ))}
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
}
