import React, { useContext, useEffect, useMemo } from 'react';
import {
  Avatar,
  AvatarProps,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Tooltip,
  useColorMode,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from 'ColorModeSwitcher';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'context/provider/UserProvider';
import CoderioIsoLogo from 'assets/img/isologo.svg';
import { UserStatePayload } from 'types/user';
import { FaSignOutAlt, FaUser } from 'react-icons/all';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const defaultLang = i18n.language.substring(0, 2);

  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    i18n.changeLanguage(defaultLang);
  }, []);

  return (
    <Select
      defaultValue={defaultLang}
      variant="flushed"
      onChange={(e) => handleChangeLanguage(e.target.value)}
      aria-label={`${t('navbar.select_language')}`}
    >
      <option value="en">EN</option>
      <option value="es">ES</option>
    </Select>
  );
};

const AvatarPicture = ({ ...props }: AvatarProps) => {
  const {
    state: { user },
  } = useContext(UserContext);
  const photoURL = useMemo(() => localStorage.getItem('photoURL') ?? '', []);
  const { displayName }: Partial<UserStatePayload> = user;

  return <Avatar name={`${displayName}`} src={photoURL} {...props} />;
};

const Header = () => {
  const { t } = useTranslation();
  const { logout } = useContext(UserContext);
  const token = localStorage.getItem('token');
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box as="header" className="header-total">
      <Flex align="center" justify="space-between" pl="5%" pr="6.38%" py={8}>
        <Heading
          as="h1"
          color="brand.500"
          display="flex"
          alignItems="center"
          aria-label="Lead Generators by Coderio"
          gap={1.5}
        >
          <Image src={CoderioIsoLogo} alt="" />
          <Box
            as="span"
            display="flex"
            flexDir="column"
            alignItems="start"
            aria-hidden="true"
          >
            <Box as="span" fontSize={{ base: '0.85rem', lg: '1.125rem' }}>
              Lead Generators
            </Box>
            <Box as="span" fontSize="0.625rem">
              by coderio_
            </Box>
          </Box>
        </Heading>
        <Flex gap={6} align="center" display={{ base: 'none', md: 'flex' }}>
          <LanguageSwitcher />
          {token && (
            <Button
              onClick={() => logout('/')}
              variant="ghost"
              textTransform="lowercase"
            >
              {t('navbar.exit')}
            </Button>
          )}
          <ColorModeSwitcher />
          {token && (
            <Tooltip label={`${t('profile_edit.title')}`}>
              <RouterLink to="/profile-edit">
                <AvatarPicture size="sm" />
              </RouterLink>
            </Tooltip>
          )}
        </Flex>
        <Flex align="center" display={{ base: 'flex', md: 'none' }}>
          <LanguageSwitcher />
          {token && (
            <Menu>
              <MenuButton
                px={4}
                py={2}
                border="none"
                _focus={{ boxShadow: 'outline' }}
              >
                <AvatarPicture size="sm" />
              </MenuButton>
              <MenuList borderRadius={16} minW="9.375rem" maxW="12.5rem" px={2}>
                <MenuItem justifyContent="end" onClick={toggleColorMode}>
                  {t('navbar.toggle_mode')}
                  {colorMode === 'light' ? (
                    <MoonIcon boxSize={5} ml={3} />
                  ) : (
                    <SunIcon boxSize={5} ml={3} />
                  )}
                </MenuItem>
                <MenuItem justifyContent="end">
                  <RouterLink to="/profile-edit">
                    {t('profile_edit.title')}
                  </RouterLink>
                  <Icon as={FaUser} boxSize={5} ml={3} aria-hidden="true" />
                </MenuItem>
                <MenuItem justifyContent="end" onClick={() => logout('/')}>
                  {t('navbar.exit')}
                  <Icon
                    as={FaSignOutAlt}
                    boxSize={5}
                    ml={3}
                    aria-hidden="true"
                  />
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
