import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export type DialogProps = {
  title: string;
  description: string;
  withCancelButton?: boolean;
  buttonText: string;
  isOpen?: boolean;
  onClose: () => unknown;
  onConfirm?: () => unknown;
};

const Dialog = ({
  title,
  description,
  buttonText,
  withCancelButton,
  isOpen = false,
  onClose,
  onConfirm,
}: DialogProps) => {
  const confirmRef = useRef(null);
  const { t } = useTranslation();
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={confirmRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          <Heading as="h2" variant="title6">
            {title}
          </Heading>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Text variant="caption1">{description}</Text>
        </AlertDialogBody>
        <AlertDialogFooter>
          {withCancelButton && (
            <Button onClick={onClose} variant="ghost" mx={2} ref={confirmRef}>
              {t('global.cancel')}
            </Button>
          )}
          <Button ref={confirmRef} onClick={onConfirm}>
            {buttonText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default Dialog;
