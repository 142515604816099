import { axiosInstance } from 'services/axios-client';

const { REACT_APP_API_URL, REACT_APP_NDA_UID } = process.env;

export const getConfidentialityText = async () => {
  try {
    return await axiosInstance.get(
      `${REACT_APP_API_URL}/info/content-text/${REACT_APP_NDA_UID}`
    );
  } catch (e) {
    console.log(e);
  }
};
