import { Stack } from '@chakra-ui/react';
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination,
} from '@ajna/pagination';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

export type PaginationData = {
  currentPage: number;
  currentElements: number;
  totalPages: number;
  totalElements: number;
};

type Props = {
  paginationInfo: PaginationData;
  handleMeetingPageChange: (num: number) => void;
};

const PaginationFC = ({
  paginationInfo: { totalPages },
  handleMeetingPageChange,
}: Props) => {
  // constants
  const { pages, pagesCount, currentPage, setCurrentPage, isDisabled } =
    usePagination({
      total: totalPages,
      limits: {
        outer: 3,
        inner: 3,
      },
      initialState: {
        pageSize: 1,
        isDisabled: false,
        currentPage: 1,
      },
    });

  // handlers
  const handlePageChange = (nextPage: number): void => {
    // -> request new data using the page number
    setCurrentPage(nextPage);
    handleMeetingPageChange(nextPage);
  };

  const slotStyles = {
    bg: 'none',
    border: '1px solid',
    borderColor: 'gray.500',
    color: 'gray.700',
    p: '1rem',
    minW: 0,
    h: '1.5rem',
    w: '1.5rem',
    fontSize: 'sm',
    borderRadius: 1,
    _hover: {
      border: '1px solid',
      bg: 'rgba(255, 40, 91, 0.31)',
      borderColor: 'rgba(255, 40, 91, 0.31)',
    },
  };

  return (
    <Stack>
      <Pagination
        pagesCount={pagesCount}
        currentPage={currentPage}
        isDisabled={isDisabled}
        onPageChange={handlePageChange}
      >
        <PaginationContainer align="center" p={4} minW="60%">
          <PaginationPrevious
            mr={3}
            onClick={() =>
              console.log(
                'Im executing my own function along with Previous component functionality'
              )
            }
            _disabled={{
              bgColor: 'transparent',
            }}
            {...slotStyles}
          >
            <ChevronLeftIcon />
          </PaginationPrevious>
          <PaginationPageGroup
            isInline
            align="center"
            gap={2}
            separator={
              <PaginationSeparator
                borderRadius={1}
                onClick={() =>
                  console.log(
                    'Im executing my own function along with Separator component functionality'
                  )
                }
                bg="blue.300"
                fontSize="sm"
                color="brand.500"
                w={7}
                jumpSize={11}
              />
            }
          >
            {pages.map((page: number) => (
              <PaginationPage
                key={`pagination_page_${page}`}
                page={page}
                onClick={() =>
                  console.log(
                    'Im executing my own function along with Page component functionality'
                  )
                }
                _current={{
                  color: 'brand.500',
                  ...slotStyles._hover,
                }}
                {...slotStyles}
              />
            ))}
          </PaginationPageGroup>
          <PaginationNext
            ml={3}
            onClick={() =>
              console.log(
                'Im executing my own function along with Next component functionality'
              )
            }
            {...slotStyles}
          >
            <ChevronRightIcon />
          </PaginationNext>
        </PaginationContainer>
      </Pagination>
    </Stack>
  );
};

export default PaginationFC;
