import { defineStyleConfig } from '@chakra-ui/react';

const Heading = defineStyleConfig({
  variants: {
    title1: {
      fontSize: '4.5rem',
      fontWeight: 700,
      lineHeight: '5rem',

      '@media (max-width: 480px)': {
        fontSize: '2rem',
        lineHeight: '2.375rem',
      },
    },
    title2: {
      fontSize: '3.75rem',
      fontWeight: 700,
      lineHeight: '4.125rem',

      '@media (max-width: 480px)': {
        fontSize: '1.5rem',
        lineHeight: '1.875rem',
      },
    },
    title3: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: '3.375rem',

      '@media (max-width: 480px)': {
        fontSize: '1.25rem',
        lineHeight: '1.625rem',
      },
    },
    title4: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '2.375rem',
      letterSpacing: '0.4%',

      '@media (max-width: 480px)': {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
      },
    },
    title5: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '1.875rem',
      letterSpacing: '0.4%',
    },
    title6: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '1.625rem',
      letterSpacing: '0.4%',
    },
  },
});

export default Heading;
