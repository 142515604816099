import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { radioAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const radioBaseStyles = {
  border: 'none',
  alignItems: 'center',

  '&:hover, &:focus': {
    boxShadow: '0 0 0 10px #f1f2f4',
  },

  _checked: {
    bg: 'brand.500',
    borderColor: 'rgba(255,255,255,0)',
    outlineColor: 'brand.500',

    _hover: {
      boxShadow: '0 0 0 10px #fff0f4',
      bg: 'brand.500',
      borderColor: 'rgba(255,255,255,0)',
    },

    _focus: {
      boxShadow: '0 0 0 10px #fff0f4',
    },

    _before: {
      bg: 'brand.500',
    },
  },

  _disabled: {
    filter: 'grayscale(100%)',
    opacity: 0.6,

    _before: {
      h: '100%',
      w: '100%',
    },
  },

  _dark: {
    outlineColor: 'gray.300',

    _checked: {
      outlineColor: 'brand.500',
    },
  },
};

const radioSizes = {
  md: definePartsStyle({
    control: {
      ...radioBaseStyles,
      w: '3',
      h: '3',
      outline: '1px solid',
      outlineColor: 'gray.600',
      marginRight: 0.5,

      _checked: {
        ...radioBaseStyles._checked,
        border: '2px solid',

        _hover: {
          ...radioBaseStyles._checked._hover,
          border: '2px solid',
        },
      },
    },
    label: { fontSize: 'md', mt: '0.05em' },
  }),
  lg: definePartsStyle({
    control: {
      ...radioBaseStyles,
      w: '3.5',
      h: '3.5',
      outline: '2px solid',
      outlineColor: 'gray.600',
      marginRight: 0.5,

      _checked: {
        ...radioBaseStyles._checked,
        border: '3px solid',

        _hover: {
          ...radioBaseStyles._checked._hover,
          border: '3px solid',
        },
      },
    },
    label: { fontSize: 'lg', mt: '0.05em' },
  }),
};

export const radioTheme = defineMultiStyleConfig({
  sizes: radioSizes,
});
