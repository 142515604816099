import React, { useContext } from 'react';
import { useFormik } from 'formik';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  useDisclosure,
} from '@chakra-ui/react';
import profileRegisterSchema from 'schemas/profile-register';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import countries from 'views/ProfileRegister/countries';
import { FaArrowLeft, FaArrowRight } from 'react-icons/all';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'context/provider/UserProvider';
import { useNavigate } from 'react-router-dom';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import FieldErrorMessage from 'components/Form/FieldErrorMessage/FieldErrorMessage';
import { RegisterUserFormValues, UserProfile } from 'types/user';
import ConfidentialityAgreementModal from 'components/ConfidentialityAgreement/ConfidentialityAgreementModal';

export default function ProfileRegister() {
  const { t } = useTranslation();
  const { state, logout, registerUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleRegisterUser = (userProfileData: RegisterUserFormValues) => {
    const { user } = state;
    const { uid, displayName, token, email }: Partial<UserProfile> = user;

    const payload = {
      ...userProfileData,
      uid,
      email,
      displayName:
        displayName ||
        `${userProfileData.firstName} ${userProfileData.lastName}`,
      ndaAccepted: true,
    };

    registerUser({ payload, token });
    navigate('/onboarding');
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    setValues,
  } = useFormik<RegisterUserFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      payoneerEmail: '',
      phoneNumber: '',
      country: '',
      linkedinUrl: '',
    },
    validationSchema: profileRegisterSchema,
    onSubmit: () => {
      onOpen();
    },
  });

  return (
    <>
      <Heading as="h2" variant="title5">
        {t('profile_registration.title')}
      </Heading>
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column">
          <Heading
            as="h3"
            variant="title6"
            mb={4}
            mt={8}
            textAlign="start"
            fontWeight={500}
            fontSize="1.125rem"
          >
            {t('profile_registration.personal_information')}
          </Heading>
          <Grid
            templateColumns="repeat(auto-fit, minmax(217px, 1fr))"
            gap="24px"
            maxW="696px"
          >
            <FormControl>
              <FormLabel>
                {t('profile_registration.form_fields.first_name')}*
              </FormLabel>
              <Input
                type="text"
                name="firstName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                variant="elevated"
                placeholder={`${t(
                  'profile_registration.form_fields.placeholders.first_name'
                )}`}
                {...(errors.firstName ? { borderColor: 'crimson' } : {})}
              />
              {errors.firstName && (
                <FieldErrorMessage
                  color="brand.700"
                  icon={<InfoOutlineIcon />}
                  fontSize="xs"
                  variant="caption1"
                  text={t(errors.firstName)}
                />
              )}
            </FormControl>
            <FormControl>
              <FormLabel>
                {t('profile_registration.form_fields.last_name')}*
              </FormLabel>
              <Input
                type="text"
                name="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                variant="elevated"
                placeholder={`${t(
                  'profile_registration.form_fields.placeholders.last_name'
                )}`}
                {...(errors.lastName ? { borderColor: 'crimson' } : {})}
              />
              {errors.lastName && (
                <FieldErrorMessage
                  color="brand.700"
                  icon={<InfoOutlineIcon />}
                  fontSize="xs"
                  variant="caption1"
                  text={t(errors.lastName)}
                />
              )}
            </FormControl>
            <FormControl>
              <FormLabel>
                {t('profile_registration.form_fields.phone')}*
              </FormLabel>
              <Input
                type="tel"
                name="phoneNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                variant="elevated"
                placeholder={`${t(
                  'profile_registration.form_fields.placeholders.phone'
                )}`}
                {...(errors.phoneNumber ? { borderColor: 'crimson' } : {})}
              />
              {errors.phoneNumber && (
                <FieldErrorMessage
                  color="brand.700"
                  icon={<InfoOutlineIcon />}
                  fontSize="xs"
                  variant="caption1"
                  text={t(errors.phoneNumber)}
                />
              )}
            </FormControl>
            <FormControl>
              <FormLabel>
                {t('profile_registration.form_fields.linkedin')}*
              </FormLabel>
              <InputGroup variant="elevated">
                <InputLeftAddon children="linkedin.com/in/" />
                <Input
                  type="text"
                  name="linkedinUrl"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.linkedinUrl}
                  placeholder={`${t(
                    'profile_registration.form_fields.placeholders.linkedin'
                  )}`}
                  {...(errors.linkedinUrl ? { borderColor: 'crimson' } : {})}
                />
              </InputGroup>
              {errors.linkedinUrl && (
                <FieldErrorMessage
                  color="brand.700"
                  icon={<InfoOutlineIcon />}
                  fontSize="xs"
                  variant="caption1"
                  text={t(errors.linkedinUrl)}
                />
              )}
            </FormControl>
            <FormControl>
              <FormLabel>
                {t('profile_registration.form_fields.country_of_residence')}*
              </FormLabel>
              <AutoComplete
                openOnFocus
                onSelectOption={({ item: { value } }) => {
                  setValues({ ...values, country: value });
                }}
                restoreOnBlurIfEmpty={false}
              >
                <AutoCompleteInput
                  autoComplete="none"
                  name="country"
                  onBlur={(e) =>
                    setValues({ ...values, country: e.target.value })
                  }
                  onChange={(e) =>
                    setValues({ ...values, country: e.target.value })
                  }
                  placeholder={`${t(
                    'profile_registration.form_fields.placeholders.country_of_residence'
                  )}`}
                  variant="elevated"
                  {...(errors.country ? { borderColor: 'crimson' } : {})}
                />
                <AutoCompleteList>
                  {countries.map((country, cid) => (
                    <AutoCompleteItem
                      key={`option-${cid}`}
                      value={country}
                      textTransform="capitalize"
                    >
                      {country}
                    </AutoCompleteItem>
                  ))}
                </AutoCompleteList>
              </AutoComplete>
              {errors.country && (
                <FieldErrorMessage
                  color="brand.700"
                  icon={<InfoOutlineIcon />}
                  fontSize="xs"
                  variant="caption1"
                  text={t(errors.country)}
                />
              )}
            </FormControl>
          </Grid>
          <Flex flexDir="column">
            <Heading
              as="h3"
              variant="title6"
              mb={4}
              mt={8}
              textAlign="start"
              fontWeight={500}
              fontSize="1.125rem"
            >
              {t('profile_registration.billing_information')}
            </Heading>
            <Grid
              autoFlow="columns"
              templateColumns="repeat(auto-fill, minmax(217px, 1fr))"
              columnGap="24px"
              maxW="696px"
            >
              <FormControl>
                <FormLabel>
                  {t('profile_registration.form_fields.payoneer_email')}*
                </FormLabel>
                <Input
                  type="text"
                  name="payoneerEmail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.payoneerEmail}
                  variant="elevated"
                  placeholder={`${t(
                    'profile_registration.form_fields.placeholders.payoneer_email'
                  )}`}
                  {...(errors.payoneerEmail ? { borderColor: 'crimson' } : {})}
                />
                {errors.payoneerEmail && (
                  <FieldErrorMessage
                    color="brand.700"
                    icon={<InfoOutlineIcon />}
                    fontSize="xs"
                    variant="caption1"
                    text={t(errors.payoneerEmail)}
                  />
                )}
              </FormControl>
            </Grid>
          </Flex>
          <Flex justifyContent="flex-end" gap={6}>
            <Button
              type="button"
              isDisabled={isSubmitting}
              leftIcon={<FaArrowLeft />}
              my={29}
              w="max-content"
              variant="ghost"
              onClick={() => logout('/')}
            >
              {t('global.back')}
            </Button>
            <Button
              type="submit"
              isDisabled={!dirty || !isValid}
              rightIcon={<FaArrowRight />}
              my={29}
              w="max-content"
            >
              {t('global.next')}
            </Button>
          </Flex>
        </Flex>
      </form>
      <ConfidentialityAgreementModal
        isOpen={isOpen}
        onClose={onClose}
        onDecline={() => logout('/')}
        onAccept={() => handleRegisterUser(values)}
      />
    </>
  );
}
