import { axiosInstance } from 'services/axios-client';

const { REACT_APP_API_URL } = process.env;

export const getDashboardFiles = async () => {
  try {
    return await axiosInstance.get(
      `${REACT_APP_API_URL}/info/content-file/category/DASHBOARD`
    );
  } catch (e) {
    console.log(e);
  }
};
