import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

const LoaderSpinner = () => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minH="60vh"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#FF285B"
          size="xl"
        />
      </Box>
    </>
  );
};

export default LoaderSpinner;
