import React from 'react';
import {
  Card,
  Divider,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import MeetingCard from './MeetingCard';
import { Meeting } from 'types/meeting';
import PaginationFC, { PaginationData } from 'components/Pagination/Pagination';
import CancelMeetingModal from './CancelMeetingModal';
import { useTranslation } from 'react-i18next';

// meetings : Meeting[]
// pagination : PaginationData
// handleMeetingPageChange : (num: number) => void
// handleMeetingCancel: (uid: string | null, reason: string) => Promise<void>

type Props = {
  meetings: Meeting[];
  pagination: PaginationData;
  handleMeetingPageChange: (num: number) => void;
  handleMeetingCancel: (uid: string | null, reason: string) => Promise<void>;
};

const MeetingsContainer = (props: Props) => {
  const { meetings, pagination, handleMeetingPageChange, handleMeetingCancel } =
    props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const containerStyles = {
    display: !meetings || !meetings.length ? 'flex' : '',
    justifyContent: !meetings || !meetings.length ? 'center' : '',
    alignItems: !meetings || !meetings.length ? 'center' : '',
    minHeight: !meetings || !meetings.length ? '400px' : '',
  };

  return (
    <Stack>
      <Card {...containerStyles} pt={5} mb={10}>
        <CancelMeetingModal
          isOpen={isOpen}
          onClose={onClose}
          handleMeetingCancel={handleMeetingCancel}
        />
        {!meetings ||
          (!meetings.length && (
            <>
              <Text px="15px">{t('meeting.empty')}</Text>
            </>
          ))}
        {meetings && (
          <>
            <Stack pb={3}>
              {meetings?.length > 0 &&
                meetings?.map((item: Meeting, index: number) => (
                  <Stack key={index}>
                    <MeetingCard {...item} onOpen={onOpen} />
                    {index < meetings.length - 1 && (
                      <HStack>
                        <Divider
                          width="92%"
                          mx="auto"
                          height="1.5px"
                          bg="#D5D8DC"
                          orientation="horizontal"
                        />
                      </HStack>
                    )}
                  </Stack>
                ))}
            </Stack>
          </>
        )}
      </Card>
      {pagination && (
        <PaginationFC
          handleMeetingPageChange={handleMeetingPageChange}
          paginationInfo={pagination}
        />
      )}
    </Stack>
  );
};

export default MeetingsContainer;
