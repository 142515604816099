import React from 'react';
import { Checkbox, CheckboxProps, Flex } from '@chakra-ui/react';

export type CheckboxCardProps = CheckboxProps & {
  label: string;
};

export default function CheckboxCard({
  label,
  ...checkboxProps
}: CheckboxCardProps) {
  return (
    <Flex
      align="center"
      bg="gray.100"
      boxShadow="0px 0px 2.33043px rgba(0, 0, 0, 0.084), 0px 1.55362px 2.33043px rgba(0, 0, 0, 0.168)"
      borderRadius="4px"
      p="11.6522px"
      _dark={{
        bg: 'gray.700',
        boxShadow:
          '0px 0px 2.33043px rgba(255, 255, 255, 0.084), 0px 1.55362px 2.33043px rgba(255, 255, 255, 0.168)',
      }}
    >
      <Checkbox {...checkboxProps} w="100%" h="100%">
        {label}
      </Checkbox>
    </Flex>
  );
}
