import React from 'react';
import {
  type EventScheduledEvent,
  InlineWidget,
  useCalendlyEventListener,
} from 'react-calendly';

const { REACT_APP_CALENDLY_ID } = process.env;

export type CalendlyWidgetProps = {
  prefill?: {
    name: string;
    email: string;
  };
  onSuccess: (event: EventScheduledEvent) => unknown;
};

export default function CalendlyWidget({
  prefill,
  onSuccess,
}: CalendlyWidgetProps) {
  useCalendlyEventListener({
    onEventScheduled: (event: EventScheduledEvent) => onSuccess(event),
  });

  return (
    <InlineWidget
      url={`https://calendly.com/${REACT_APP_CALENDLY_ID}?hide_gdpr_banner=1&hide_branding=1`}
      styles={{
        height: '800px',
        boxShadow: 'none',
        border: 'none',
      }}
      prefill={prefill}
    />
  );
}
