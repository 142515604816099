import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const elevated = definePartsStyle({
  field: {
    bg: 'gray.50',
    borderRadius: '4px',
    boxShadow:
      '0px 0px 2.33043px rgba(0, 0, 0, 0.084), 0px 1.55362px 2.33043px rgba(0, 0, 0, 0.168)',
    color: 'gray.800',
    _placeholder: {
      color: 'gray.600',
    },
    '&::-webkit-input-placeholder': {
      color: 'gray.600',
    },
  },
  addon: {
    boxShadow:
      '0px 0px 2.33043px rgba(0, 0, 0, 0.084), 0px 1.55362px 2.33043px rgba(0, 0, 0, 0.168)',
    _dark: {
      bg: 'gray.300',
      color: 'gray.800',
    },
  },
});

export const inputTheme = defineMultiStyleConfig({
  variants: { elevated },
});
