import { Box, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
import MetricsDisplay from 'components/Metrics/MetricsDisplay';
import Schedule from 'components/Meetings/Schedule';
import React from 'react';
import MeetingsContainer from 'components/Meetings/MeetingsContainer';
import useMeetings from 'hooks/useMeetings';
import OBMeetingBanner from 'components/OBMeetingBanner/OBMeetingBanner';
import { useTranslation } from 'react-i18next';

const DashboardMain = (): JSX.Element => {
  const { t } = useTranslation();
  const { meetings, pagination, handleMeetingPageChange, handleMeetingCancel } =
    useMeetings();

  const showBanner = useBreakpointValue({ base: false, lg: true });

  return (
    <Box>
      <Grid
        templateAreas={{
          base: `
            "metrics"
            "user-actions"
            "meetings"
          `,
          xl: `
            "metrics user-actions"
            "meetings user-actions"        
          `,
        }}
        templateColumns={{ xl: 'minmax(564px, 2fr) 1fr' }}
        gap={6}
      >
        <GridItem area="metrics" as="section">
          <MetricsDisplay meetings={meetings} />
        </GridItem>
        <GridItem
          area="meetings"
          as="section"
          aria-label={`${t('meeting.list.title')}`}
        >
          <MeetingsContainer
            meetings={meetings}
            pagination={pagination}
            handleMeetingPageChange={handleMeetingPageChange}
            handleMeetingCancel={handleMeetingCancel}
          />
        </GridItem>
        <GridItem area="user-actions" display="flex" flexDir="column" gap={6}>
          <Schedule />
          {showBanner && <OBMeetingBanner />}
        </GridItem>
      </Grid>
    </Box>
  );
};

export default DashboardMain;
