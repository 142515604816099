import { defineStyleConfig } from '@chakra-ui/react';

const Text = defineStyleConfig({
  variants: {
    subtitle1: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '1.875rem',

      '@media (max-width: 480px)': {
        fontSize: '1.25rem',
        lineHeight: '1.625rem',
      },
    },
    subtitle2: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.625rem',

      '@media (max-width: 480px)': {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
      },
    },
    subtitle3: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.5rem',

      '@media (max-width: 480px)': {
        fontSize: '1rem',
        lineHeight: '1.25rem',
      },
    },
    caption1: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.25rem',

      '@media (max-width: 480px)': {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
      },
    },
    caption2: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1rem',
    },
  },
});

export default Text;
