import {
  CalendarCheckIcon,
  CalendarIconClose,
  CurrencyIcon,
  HourIcon,
  TrashIcon,
} from 'components/Icons';

type Props = {
  status:
    | 'PENDING'
    | 'COMPLETED'
    | 'BILLED'
    | 'CANCELLED'
    | 'PROCESSING'
    | 'FAILED';
  colorDicider: (
    status:
      | 'PENDING'
      | 'COMPLETED'
      | 'BILLED'
      | 'CANCELLED'
      | 'PROCESSING'
      | 'FAILED'
  ) => string;
  onOpen: () => void;
  uid: string | number;
};

const IconDecider = ({ status, colorDicider, onOpen, uid }: Props) => {
  switch (status) {
    case 'BILLED':
      return <CurrencyIcon fontSize="20px" color={colorDicider(status)} />;
    case 'COMPLETED':
      return <CalendarCheckIcon fontSize="20px" />;
    case 'CANCELLED':
    case 'FAILED':
      return <CalendarIconClose color="gray.500" fontSize="20px" />;
    case 'PROCESSING':
      return <HourIcon color="gray.500" fontSize="20px" />;
    case 'PENDING':
      return (
        <TrashIcon
          onClick={() => {
            localStorage.setItem('meetingId', uid.toString());
            onOpen();
          }}
          color="#FF285B"
          cursor="pointer"
        />
      );
    default:
      return <></>;
  }
};

export default IconDecider;
