import {
  Meeting,
  PostMeetingClientInfo,
  PutMeetingClientInfo,
  PutMeetingParams,
} from 'types/meeting';
import { axiosInstance } from 'services/axios-client';
import { AxiosError, AxiosResponse } from 'axios';
import { ErrorResponse } from 'types/shared/error';

const { REACT_APP_API_URL } = process.env;

type Response = {
  code: number;
  description: string;
  message: string;
  type: string;
};

export const getUserMeetingsInfo = async ({
  uid,
  page,
}: {
  uid: string;
  page: number;
}): Promise<AxiosResponse | undefined> => {
  try {
    return await axiosInstance.get(
      `/user-profile/${uid}/meetings?page=${page}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const postMeetingClientInfo = async ({
  payload,
}: PostMeetingClientInfo): Promise<AxiosResponse | ErrorResponse<Response>> => {
  try {
    return await axiosInstance.post(`${REACT_APP_API_URL}/meeting`, payload);
  } catch (error) {
    return {
      status: (error as AxiosError).response?.status,
      message: (error as AxiosError).message,
      data: (error as AxiosError).response?.data,
    };
  }
};

export const putMeetingClientInfo = async ({
  uid,
  payload,
}: PutMeetingClientInfo): Promise<AxiosResponse | ErrorResponse<Response>> => {
  try {
    return await axiosInstance.put(
      `${REACT_APP_API_URL}/meeting/${uid}`,
      payload
    );
  } catch (error) {
    return {
      status: (error as AxiosError).response?.status,
      message: (error as AxiosError).message,
      data: (error as AxiosError).response?.data,
    };
  }
};

export const putMeetingCalendlyEventURI = async ({
  uid,
  payload,
}: PutMeetingParams): Promise<AxiosResponse | ErrorResponse<Response>> => {
  try {
    return await axiosInstance.put(
      `${REACT_APP_API_URL}/meeting/${uid}/calendly-event`,
      payload
    );
  } catch (error) {
    return {
      status: (error as AxiosError).response?.status,
      message: (error as AxiosError).message,
    };
  }
};

export const cancelMeeting = async ({
  uid,
  reason,
}: {
  uid: string | null;
  reason: string;
}): Promise<Meeting | ErrorResponse<Response>> => {
  try {
    const result = axiosInstance.put(`/meeting/${uid}/cancellation`, {
      reason,
    });
    return (await result).data;
  } catch (error) {
    return {
      status: (error as AxiosError).response?.status,
      message: (error as AxiosError).message,
    };
  }
};
