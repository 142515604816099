import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import meetingClientInfoSchema from 'schemas/meeting';
import {
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import FieldErrorMessage from 'components/Form/FieldErrorMessage/FieldErrorMessage';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import React, { useEffect } from 'react';
import { MeetingPayload } from 'types/meeting';

export type NewMeetingFormValues = Partial<MeetingPayload>;

type NewMeetingFormProps = {
  onReadyStateChange: (valid: boolean) => unknown;
  onChange: (values: NewMeetingFormValues) => unknown;
  prefill?: Partial<MeetingPayload>;
};

export default function NewMeetingForm({
  onReadyStateChange,
  onChange,
  prefill,
}: NewMeetingFormProps) {
  const { t } = useTranslation();

  const initialValues: NewMeetingFormValues = {
    acquaintanceName: prefill?.acquaintanceName || '',
    company: prefill?.company || '',
    acquaintancePosition: prefill?.acquaintancePosition || '',
    business: prefill?.business || '',
    acquaintanceLinkedinProfile: prefill?.acquaintanceLinkedinProfile || '',
    employeeCount: prefill?.employeeCount || '',
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema: meetingClientInfoSchema,
    onSubmit: async () => {},
  });

  useEffect(() => {
    onReadyStateChange(isSubmitting || !dirty || !isValid);
  }, [dirty, isSubmitting, isValid]);

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <Heading
        as="h3"
        variant="title6"
        fontWeight={500}
        mb={6}
        textAlign="start"
      >
        {t('meeting.new_meeting.client_info')}
      </Heading>
      <Grid templateColumns="repeat(auto-fill, minmax(338px, 1fr))" gap={39}>
        <FormControl>
          <FormLabel>
            {t('meeting.new_meeting.form_fields.full_name')}*
          </FormLabel>
          <Input
            type="text"
            name="acquaintanceName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.acquaintanceName}
            variant="elevated"
            placeholder={`${t(
              'meeting.new_meeting.form_fields.placeholders.full_name'
            )}`}
            {...(errors.acquaintanceName ? { borderColor: 'crimson' } : {})}
          />
          {errors.acquaintanceName && (
            <FieldErrorMessage
              color="brand.700"
              icon={<InfoOutlineIcon />}
              variant="caption2"
              text={t(errors.acquaintanceName)}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>{t('meeting.new_meeting.form_fields.company')}*</FormLabel>
          <Input
            type="text"
            name="company"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.company}
            variant="elevated"
            placeholder={`${t(
              'meeting.new_meeting.form_fields.placeholders.company'
            )}`}
            {...(errors.company ? { borderColor: 'crimson' } : {})}
          />
          {errors.company && (
            <FieldErrorMessage
              color="brand.700"
              icon={<InfoOutlineIcon />}
              variant="caption2"
              text={t(errors.company)}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>
            {t('meeting.new_meeting.form_fields.position')}*
          </FormLabel>
          <Input
            type="text"
            name="acquaintancePosition"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.acquaintancePosition}
            variant="elevated"
            placeholder={`${t(
              'meeting.new_meeting.form_fields.placeholders.position'
            )}`}
            {...(errors.acquaintancePosition ? { borderColor: 'crimson' } : {})}
          />
          {errors.acquaintancePosition && (
            <FieldErrorMessage
              color="brand.700"
              icon={<InfoOutlineIcon />}
              variant="caption2"
              text={t(errors.acquaintancePosition)}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>
            {t('meeting.new_meeting.form_fields.business')}*
          </FormLabel>
          <Input
            type="text"
            name="business"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.business}
            variant="elevated"
            placeholder={`${t(
              'meeting.new_meeting.form_fields.placeholders.business'
            )}`}
            {...(errors.business ? { borderColor: 'crimson' } : {})}
          />
          {errors.business && (
            <FieldErrorMessage
              color="brand.700"
              icon={<InfoOutlineIcon />}
              variant="caption2"
              text={t(errors.business)}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>
            {t('meeting.new_meeting.form_fields.linkedin')}*
          </FormLabel>
          <InputGroup variant="elevated">
            <InputLeftAddon children="linkedin.com/in/" />
            <Input
              type="text"
              name="acquaintanceLinkedinProfile"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.acquaintanceLinkedinProfile}
              placeholder={`${t(
                'meeting.new_meeting.form_fields.placeholders.linkedin'
              )}`}
              {...(errors.acquaintanceLinkedinProfile
                ? { borderColor: 'crimson' }
                : {})}
            />
          </InputGroup>
          {errors.acquaintanceLinkedinProfile && (
            <FieldErrorMessage
              color="brand.700"
              icon={<InfoOutlineIcon />}
              variant="caption2"
              text={t(errors.acquaintanceLinkedinProfile)}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>
            {t('meeting.new_meeting.form_fields.employee_count')}*
          </FormLabel>
          <Input
            type="text"
            name="employeeCount"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.employeeCount}
            variant="elevated"
            placeholder={`${t(
              'meeting.new_meeting.form_fields.placeholders.employee_count'
            )}`}
            {...(errors.employeeCount ? { borderColor: 'crimson' } : {})}
          />
          {errors.employeeCount && (
            <FieldErrorMessage
              color="brand.700"
              icon={<InfoOutlineIcon />}
              variant="caption2"
              text={t(errors.employeeCount)}
            />
          )}
        </FormControl>
      </Grid>
    </form>
  );
}
