import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

export const axiosInstance: AxiosInstance = axios.create({
  // baseURL: 'https://dev.prospectors.coderio.co/pros/api/v1',
  // baseURL: 'http://localhost:8080/pros/api/v1',
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (
    config: InternalAxiosRequestConfig<unknown>
  ): InternalAxiosRequestConfig<unknown> => {
    // Retrieve the token from local storage
    const token: string | null = localStorage.getItem('token');

    // Create an empty headers object if it doesn't exist
    config.headers = config.headers || {};

    // Set the token in the request headers if it exists
    if (token) {
      config.headers['Authorization'] = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error?.response?.message);
  }
);
