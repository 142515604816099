import React, { useContext } from 'react';
import DashboardMain from 'views/Dashboard/DashboardMain';
import {
  Box,
  Grid,
  GridItem,
  Heading,
  useBreakpointValue,
} from '@chakra-ui/react';
import { UserContext } from 'context/provider/UserProvider';
import { UserStatePayload } from 'types/user';
import { useTranslation } from 'react-i18next';
import { DashboardProvider } from 'context/provider/DashboardProvider';
import OBMeetingBanner from 'components/OBMeetingBanner/OBMeetingBanner';
import UsefulResources from 'components/UsefulResources/UsefulResources';

const Dashboard = () => {
  const { t } = useTranslation();
  const {
    state: { user },
  } = useContext(UserContext);

  const { firstName }: Partial<UserStatePayload> = user;

  const showBanner = useBreakpointValue({ base: true, lg: false });

  return (
    <DashboardProvider>
      <Grid
        templateAreas={{
          base: `
              "heading"
              "resources"
              "main-content"
            `,
          lg: `
              ". heading"
              "resources main-content"
            `,
        }}
        templateColumns={{
          lg: 'minmax(224px, 0.5fr) 1.5fr',
          '2xl': '0.5fr 1.5fr',
        }}
        gap={6}
      >
        <GridItem area="heading">
          <Heading as="h2" variant="title4">
            {t('dashboard.hello_text')} {firstName}
          </Heading>
        </GridItem>
        <GridItem area="resources">
          <UsefulResources />
          {showBanner && (
            <Box mt={6}>
              <OBMeetingBanner />
            </Box>
          )}
        </GridItem>
        <GridItem area="main-content">
          <DashboardMain />
        </GridItem>
      </Grid>
    </DashboardProvider>
  );
};

export default Dashboard;
